@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.etd-templates-help-modal {
  width: 850px;
  height: 95%;
}
.template-selection-editing .block input[type="text"] {
  font-size: 14px;
}
.templates-settings-view {
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
}
.templates-settings-view .etd-templates-help {
  cursor: pointer;
  margin-left: 10px;
}
.templates-settings-view .etd-templates {
  height: 100%;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.templates-settings-view .selected-templates {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}
.templates-settings-view .template-select-text {
  margin-right: 10px;
}
.templates-settings-view .base-template-selection select {
  width: initial;
}
.templates-settings-view .block {
  padding: 5px;
  margin-bottom: 10px;
  background: #f6f6f6;
  border: 1px solid #bfbfbf;
}
.templates-settings-view .block:last-child {
  margin-bottom: 0px;
}
.templates-settings-view .block-sections-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.templates-settings-view .block-sections-list li {
  height: initial;
  line-height: initial;
  padding-left: 10px;
  margin-bottom: 5px;
}
.templates-settings-view .block-sections-list li:first-child {
  height: initial;
  line-height: initial;
}
.templates-settings-view .block-sections-list .disabled {
  opacity: 0.3;
}
.templates-settings-view .section-checkbox input {
  margin-right: 4px;
}
.templates-settings-view .section-checkbox span.section-name .with-desc {
  text-transform: uppercase;
}
.templates-settings-view .section-desc {
  font-weight: bold;
  padding-left: 16px;
}
.templates-settings-view .section-options .btn-icon-container button {
  border-left: none;
}
.templates-settings-view .template-name-block input {
  width: 100%;
}
.templates-settings-view .settings-bottom-buttons-row {
  margin: 0;
}
.templates-settings-view .selected-template-block {
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #bfbfbf;
}
.templates-settings-view .selected-template-block .template-box .expanded {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  border: none;
  background-color: inherit;
  padding: 0;
}
.templates-settings-view .selected-template-block .template-box .expanded li {
  background-color: inherit;
  border: none;
}
.templates-settings-view .selected-template-block .template-box .template-selection-editing .sections-selection {
  margin-bottom: 10px;
  overflow: auto;
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
}
.templates-settings-view .selected-template-block .template-box .template-selection-editing .sections-selection .toggle-panel .header {
  margin-top: 0px;
}
.templates-settings-view .presentations-table {
  width: 100%;
}
.templates-settings-view .presentations-table .presentations-section-checkbox {
  line-height: 40px;
}
.templates-settings-view .presentations-table td {
  width: 33%;
}
.templates-settings-view .presentations-table td ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.templates-settings-view .presentations-table td ul li {
  height: initial;
  line-height: initial;
  padding-left: 10px;
  margin-bottom: 5px;
}
.templates-settings-view .presentations-table td ul li:first-child {
  height: initial;
  line-height: initial;
}
.templates-settings-view .presentations-table td ul .disabled {
  opacity: 0.3;
}
.general-information-view {
  overflow: auto;
}
.mdg-topics {
  padding: 0 10px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}
.mdg-topics .mdg-topics__topic {
  background: #dedede;
  height: 40px;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 10px;
  transition: background 0.2s ease-in-out;
}
.mdg-topics .mdg-topics__topic:hover {
  background: #f2f2f2;
}
.mdg-topics .mdg-topics__topic:not(:last-child) {
  margin-bottom: 5px;
}
.mdg-topics .mdg-topics__topic .topic__name {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.mdg-topics .mdg-topics__topic .topic__name input {
  width: 100%;
}
.mdg-topics .mdg-topics__topic .topic__buttons {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-topics .mdg-topics__topic .topic__buttons .topic__buttons__codes {
  margin-left: 10px;
}
.mdg-topics .mdg-topics__topic .topic__buttons .topic__buttons__codes button {
  color: #0091c3;
  background: #fff;
}
.mdg-topics .mdg-topics__topic .topic__buttons .btn-icon-container {
  margin-left: 10px;
  padding-bottom: 4px;
}
.mdg-topics-toolbar {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-topics-toolbar h2 {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.mdg-topics-toolbar button {
  margin-left: 10px;
}
.mdg-topics-field {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mdg-topics-field.loading {
  position: relative;
  min-height: 40px;
}
.mdg-topics-field .mdg-topics-field__list {
  padding: 6px 0;
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
}
.mdg-topics-field .mdg-topics-field__button-edit {
  margin-left: 10px;
}
.mdg-topics-field .mdg-topics-field__button-edit .btn-icon-container button {
  width: 10px;
  height: 10px;
  font-size: 13px;
}
.mdg-topics-field-modal .mdg-topics-field-modal__topics {
  margin: 10px 0;
}
.mdg-topics-field-modal .mdg-topics-field-modal__topics .topics__topic {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.mdg-topics-field-modal .mdg-topics-field-modal__topics .topics__topic .topic__checkbox {
  margin-right: 10px;
}
.mdg-topics-field-modal .mdg-topics-field-modal__topics .topics__topic .topic__checkbox input {
  cursor: pointer;
}
.mdg-topics-field-modal .mdg-topics-field-modal__topics .topics__topic .topic__name {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  background: #dedede;
  height: 40px;
  line-height: 40px;
  transition: background 0.2s ease-in-out;
  padding: 0 10px;
}
.mdg-topics-field-modal .mdg-topics-field-modal__topics .topics__topic:not(:last-child) {
  margin-bottom: 5px;
}
.mdg-topics-field-modal .mdg-topics-field-modal__topics .topics__topic:hover .topic__name {
  background: #f2f2f2;
}
.mdg-topics-field-modal .mdg-topics-field-modal__button {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
