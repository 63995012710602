@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
#header {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.2);
  box-shadow: 0 5px 5px rgba(0,0,0,0.2);
}
#header .wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0 5px;
}
#header > a,
#header > button {
  white-space: nowrap;
}
#header .logo {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: normal;
  color: #b3b4b5;
  display: block;
  display: inline;
  float: left;
  overflow: hidden;
  width: 8.264462809917356%;
  margin: 0 0.413223140495868%;
  *width: 8.264462809917356% -correction;
  *margin: 0 0.413223140495868% -correction;
  width: 130px;
}
#header .logo a {
  background: url("../images/logo_new.png") top left no-repeat;
  text-indent: -9999px;
  background-position: left center;
  -webkit-background-size: 130px;
  -moz-background-size: 130px;
  background-size: 130px;
}
#header .update-installed-info {
  display: none;
  background-color: #cecece;
  padding: 0 15px;
}
#header .update-installed-info a.whats-new-link {
  display: inline;
  color: #5a6d8d;
  text-decoration: underline;
}
#header .update-installed-info,
#header .update-installed-info a {
  font-size: 14px;
  color: #5395cf !important;
}
#header .update-installed-info span {
  font-weight: bold;
}
#header .workspace-name {
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
}
#header .projects {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  font-size: 16px;
  padding-right: 10px;
  z-index: 11;
  max-width: calc(100vw - 315px);
}
#header .projects .header-projects-list {
  margin: 2px 5px 5px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  overflow: auto;
  max-height: calc(100vh - 200px);
  max-width: calc(100vw - 380px);
}
#header .projects .project {
  overflow: hidden;
  white-space: nowrap;
}
#header .projects .project.active {
  font-weight: bold;
}
#header .projects .title {
  padding-left: 5px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: calc(100vw - 380px);
  white-space: nowrap;
  cursor: pointer;
}
#header .projects .title > a {
  display: inline;
}
#header .projects .title > a:before {
  font-family: 'gdt';
  content: '\e072';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header .projects .title > a * {
  margin-left: 0.5rem;
}
#header .projects .title > a:before {
  color: #3783a5;
  position: relative;
  font-size: 12px;
  top: -2px;
  padding-right: 10px;
}
#header a,
#header button {
  display: inline-block;
  height: 40px;
  width: 100%;
}
#header a,
#header button,
#header a:link,
#header button:link,
#header a:visited,
#header button:visited,
#header a:hover,
#header button:hover,
#header a:active,
#header button:active {
  color: inherit;
  text-decoration: none;
}
#header .buttons-row a.btn,
#header .buttons-row button,
#header .buttons-row a.btn:link,
#header .buttons-row button:link,
#header .buttons-row a.btn:visited,
#header .buttons-row button:visited,
#header .buttons-row a.btn:hover,
#header .buttons-row button:hover,
#header .buttons-row a.btn:active,
#header .buttons-row button:active {
  color: #fff;
}
#header a.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#header a:hover:not(.disabled):not(.caption) {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
#header menu {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
}
#header menu button,
#header menu a {
  min-width: 35px;
  height: 37px;
  margin-right: 5px;
  outline: none;
}
#header menu .donation {
  vertical-align: middle;
  margin: 0 20px 0 8px;
  font-size: 12px;
}
#header menu .donation .donate {
  position: relative;
  bottom: -6px;
  width: auto;
  height: 21px;
  margin-left: 8px;
  padding: 0 15px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: -webkit-linear-gradient(left, #e95400, #d015ff);
  background: -moz-linear-gradient(left, #e95400, #d015ff);
  background: -o-linear-gradient(left, #e95400, #d015ff);
  background: -ms-linear-gradient(left, #e95400, #d015ff);
  background: linear-gradient(to right, #e95400, #d015ff);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
#header menu .donation .donate:hover {
  color: #f00;
  padding: 1px 15px;
  background: -webkit-linear-gradient(#fff, #fff), -webkit-linear-gradient(left, #e95400, #d015ff);
  background: -moz-linear-gradient(#fff, #fff), -moz-linear-gradient(left, #e95400, #d015ff);
  background: -o-linear-gradient(#fff, #fff), -o-linear-gradient(left, #e95400, #d015ff);
  background: -ms-linear-gradient(#fff, #fff), -ms-linear-gradient(left, #e95400, #d015ff);
  background: linear-gradient(#fff, #fff), linear-gradient(to right, #e95400, #d015ff);
  -webkit-background-size: calc(100% - 2px) calc(100% - 2px), cover;
  -moz-background-size: calc(100% - 2px) calc(100% - 2px), cover;
  background-size: calc(100% - 2px) calc(100% - 2px), cover;
  background-position: center, top left;
  background-repeat: no-repeat, no-repeat;
}
#header menu .donation .donate:hover svg text {
  fill: url("#grad1");
}
#header menu .donation .donate svg text {
  text-anchor: middle;
}
#header menu .feedback:before,
#header menu .settings:before {
  color: #3783a5;
  font-size: 20px;
}
#header menu .feedback:before {
  font-family: 'gdt';
  content: '\e002';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu .feedback * {
  margin-left: 0.5rem;
}
#header menu .settings:before {
  font-family: 'gdt';
  content: '\e001';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu .settings * {
  margin-left: 0.5rem;
}
#header menu .settings:before {
  font-size: 26px;
}
#header menu .notifications {
  font-size: 20px;
  font-size: 2rem;
  padding-right: 0.5rem;
  line-height: 40px;
}
#header menu .notifications:before {
  font-family: 'gdt';
  content: '\e008';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu .notifications * {
  margin-left: 0.5rem;
}
#header menu .notifications.on:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu .notifications.on * {
  margin-left: 0.5rem;
}
#header menu .connection {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  background-color: #2d3236;
  border: 1px solid #24282b;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(45,50,54,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(45,50,54,0.5);
}
#header menu .connection.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #2d3236;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #2d3236;
}
#header menu .connection.disconnected {
  background-color: #c80000;
  border: 1px solid #a00000;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(200,0,0,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(200,0,0,0.5);
}
#header menu .connection.disconnected.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #c80000;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #c80000;
}
#header menu .connection.unauthorized {
  background-color: #f8bd24;
  border: 1px solid #dca107;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(248,189,36,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(248,189,36,0.5);
}
#header menu .connection.unauthorized.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #f8bd24;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #f8bd24;
}
#header menu .connection.connected {
  background-color: #87bb53;
  border: 1px solid #6c9b3d;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(135,187,83,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(135,187,83,0.5);
  -webkit-transition: background-color 350ms, box-shadow 700ms;
  -moz-transition: background-color 350ms, box-shadow 700ms;
  -o-transition: background-color 350ms, box-shadow 700ms;
  -ms-transition: background-color 350ms, box-shadow 700ms;
  transition: background-color 350ms, box-shadow 700ms;
}
#header menu .connection.connected.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #87bb53;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #87bb53;
}
#header .user-info {
  padding-left: 15px;
  border-left: 1px solid #bfbfbf;
  width: auto;
  display: inline;
}
#header .user-info:before {
  content: "";
}
#header .user-info:after {
  margin-left: 0.5rem;
  font-family: 'gdt';
  content: '\e072';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header .user-info:after {
  position: relative;
  top: -2px;
  color: #3783a5;
  padding: 0 10px;
  font-size: 12px;
}
#header #header-message {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
#header #header-message > * {
  height: 25px;
  line-height: 25px;
  display: none;
}
#header #header-message a {
  display: inline;
  color: #5a6d8d;
  text-decoration: underline;
  height: 40px;
}
#header #header-message .info {
  background: #958509 url("../images/hm-bg-i.png") left top repeat-x;
}
#header #header-message .info,
#header #header-message .info a {
  color: #fff;
}
#header #header-message .warning {
  background: #950000 url("../images/hm-bg-w.png") left top repeat-x;
  color: #fff;
}
#header #header-message .warning .question-mark-sign {
  font-size: 14px;
  vertical-align: middle;
  font-weight: bold;
  border-width: 2px;
  border-color: #fff;
  height: 18px;
  width: 18px;
  margin-left: 5px;
  margin-bottom: 4px;
}
#header .standard-list li:first-child,
#header .standard-list li:last-child,
#header .standard-list li:only-child {
  height: 40px;
}
#header .standard-list li {
  height: 40px;
  line-height: 40px;
}
#header .standard-list li {
  display: block;
  border-left-width: 0;
  border-right-width: 0;
}
#header.alternate .logo {
  display: inline;
  float: left;
  overflow: hidden;
  width: 17.355371900826448%;
  margin: 0 0.413223140495868%;
  *width: 17.355371900826448% -correction;
  *margin: 0 0.413223140495868% -correction;
}
#header.alternate .projects.opened > a {
  background: url("../images/arrow-up.png") left center no-repeat;
  padding-left: 30px;
}
#header.alternate .projects > a {
  background: url("../images/arrow-down.png") left center no-repeat;
  padding-left: 30px;
}
.user-menu {
  position: relative;
  margin-right: 5px;
}
.user-menu .avatar {
  cursor: pointer;
  outline: none;
  position: relative;
  display: block;
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px;
  border: 1px solid transparent;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: url("../images/person.svg") no-repeat;
}
.user-menu__container {
  background: #fff;
  margin-top: 10px;
  margin-right: 10px;
  padding: 16px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #009cca;
  outline: none;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  font-size: 14px;
  line-height: 20px;
  color: #2d3236;
  min-width: 400px;
}
.user-menu__container .bottom-buttons {
  margin-bottom: 0px;
}
.user-menu__container .bottom-buttons .user-account .user-avatar-icon {
  display: inline-block;
  background: url("../images/svg/user.svg") no-repeat 0 0;
  fill: #fff;
}
.user-information {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.user-information__avatar {
  -webkit-flex-basis: 50px;
  flex-basis: 50px;
  height: 50px;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
  background: url("../images/person.svg") no-repeat;
}
.user-information__data {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-left: 20px;
}
.user-information__data h3 {
  margin: 0px;
  padding: 0px;
}
.links {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 16px;
}
.links .social-links {
  padding: 10px;
  background: #f2f2f2;
  text-align: center;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.links .social-links .social-links__container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.links .social-links .social-links__container a {
  display: block;
  width: 23px;
  height: 23px;
  margin: 5px;
}
.links .social-links .social-links__container a.facebook {
  background: url("../images/svg/fb.svg");
}
.links .social-links .social-links__container a.twitter {
  background: url("../images/svg/twitter.svg");
}
.links .social-links .social-links__container a:hover {
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  filter: alpha(opacity=85);
}
.links .help-links {
  padding: 10px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  text-align: right;
  background: #ddeaf9;
}
.links .help-links a {
  display: block;
  text-decoration: none;
  color: #0071bc;
}
.workspaces {
  margin-top: 20px;
  position: relative;
}
.workspaces .workspaces__title {
  text-transform: uppercase;
  font-weight: bold;
}
.workspaces .workspaces__link {
  display: block;
  padding: 5px 10px;
  background: #f2f2f2;
  margin-bottom: 1px;
  width: 100%;
  text-align: left;
}
.workspaces .workspaces__link.active {
  font-weight: bold;
}
.workspaces .workspaces__link--organization {
  text-transform: uppercase;
}
.workspaces .workspaces-screen-link {
  margin-top: 10px;
  text-align: right;
}
.workspaces .workspaces-screen-link.workspaces-screen {
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
}
.user-menu__container .workspaces-screen-link a,
.user-menu__container .admin-panel-link a,
.user-menu__container .workspaces-screen-link a:visited,
.user-menu__container .admin-panel-link a:visited,
.user-menu__container .workspaces-screen-link a:hover,
.user-menu__container .admin-panel-link a:hover {
  font-size: 14px;
  font-style: italic;
  color: #29abe2;
  text-decoration: none;
}
.user-menu__container .workspaces-screen-link a:hover,
.user-menu__container .admin-panel-link a:hover,
.user-menu__container .workspaces-screen-link a:visited:hover,
.user-menu__container .admin-panel-link a:visited:hover,
.user-menu__container .workspaces-screen-link a:hover:hover,
.user-menu__container .admin-panel-link a:hover:hover {
  font-weight: bold;
}
.user-menu__container .admin-panel-link img {
  height: 28px;
  vertical-align: middle;
  margin: 0 12px 0 10px;
}
.external-accounts .external-accounts__provider:not(:last-child) {
  margin-bottom: 5px;
}
.archie-removal-dialog .archie-removal-dialog__archie_logo {
  position: absolute;
  top: 10px;
  right: 20px;
}
.archie-removal-dialog h3 {
  display: block;
  background: #fff8de;
  text-align: center;
  padding: 5px 0;
  text-decoration: underline;
  font-weight: normal;
}
.archie-removal-dialog h3:hover {
  text-decoration: underline !important;
}
.archie-removal-dialog h4 {
  margin-bottom: 0;
}
.archie-removal-dialog .archie-removal-dialog__underlined {
  text-decoration: underline;
}
.archie-removal-dialog .archie-removal-dialog__underlined:hover {
  text-decoration: underline !important;
}
.archie-removal-dialog .archie-removal-dialog__footer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: end;
  -moz-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.archie-removal-dialog .archie-removal-dialog__footer .footer__left {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.archie-removal-dialog .archie-removal-dialog__footer .footer__left p {
  margin-top: 0;
}
.archie-removal-dialog .archie-removal-dialog__footer .footer__right .item-with-checkbox {
  margin-bottom: 14px;
}
.archie-removal-dialog .archie-removal-dialog__footer .footer__right .item-with-checkbox .item-with-checkbox__item {
  font-weight: bold;
}
.archie-removal-dialog .archie-removal-dialog__button {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.archie-removal-dialog .archie-removal-dialog__button button {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
