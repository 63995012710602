@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.access-rights {
  position: relative;
}
.access-rights .select-input,
.access-rights .options-container {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  width: 100%;
  color: #5c5d5f;
}
.team-members-view {
  min-width: 910px;
}
.team-members-view .ellipsis-wrapper {
  position: relative;
}
.team-members-view .caption a:hover:after {
  position: absolute;
  top: 0;
  content: '';
  width: 16px;
  height: 12px;
  margin-left: 5px;
  background: url("../images/mail.png") top left no-repeat;
  text-indent: -9999px;
}
.team-members-view li.expanded {
  height: 123px;
}
.coi-questions-list,
.coi-answers {
  max-height: 500px;
  overflow: auto;
}
.coi-questions-list {
  width: 400px;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex: 1;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.coi-questions-list .conflict-column {
  width: 50px;
  text-align: center;
}
.coi-questions-list .conflict-column input {
  cursor: pointer;
}
.coi-answers {
  width: 400px;
  margin-left: 20px;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  -ms-flex: 1;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.coi-members-list {
  height: calc(100vh - 250px);
  overflow-y: auto;
}
.coi-members-list .member-cv {
  line-height: 40px;
  margin-right: 40px;
  width: auto;
  font-size: 14px;
  border-left: none;
  color: #3783a5 !important;
}
.coi-members-list .member-cv:hover {
  text-decoration: underline !important;
}
.coi-members-list .member-cv:before {
  font-family: 'gdt';
  content: '\e098';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.coi-members-list .member-cv * {
  margin-left: 0.5rem;
}
.coi-members-list .member-cv:before {
  display: inline-block;
  margin-top: -4px;
}
.coi-members-list .member-name {
  cursor: pointer;
  line-height: 40px;
}
.coi-members-list .member-name:hover:after {
  top: 15px;
}
.coi-members-list .member-status {
  margin-left: -10px;
  height: 40px;
  width: 5px;
  margin-right: 10px;
}
.coi-members-list .member-status.not-sent {
  background: #999;
}
.coi-members-list .member-status.new {
  background: #ffb012;
}
.coi-members-list .member-status.mail-sent {
  background: #ffb012;
}
.coi-members-list .member-status.in-progress {
  background: #ffb012;
}
.coi-members-list .member-status.completed {
  background: #269f0c;
}
.coi-members-list .member-status.conflict {
  background: #dc3f3f;
}
.coi-members-list .member-status.connection-error {
  background: -webkit-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: -moz-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: -o-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: -ms-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: repeating-linear-gradient(-65deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
}
.coi-view .no-conflict {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 5px;
}
.coi-view .coi-options {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bfbfbf;
}
.coi-view .coi-options input[type=radio] {
  margin: 0;
  margin-left: 5px;
  margin-top: 5px;
}
.coi-view .coi-options .open-send-notifications-modal,
.coi-view .coi-options .send-reminder-mail,
.coi-view .coi-options .import-coi {
  cursor: pointer;
}
.coi-view .coi-options .open-send-notifications-modal.disabled,
.coi-view .coi-options .send-reminder-mail.disabled,
.coi-view .coi-options .import-coi.disabled {
  cursor: default;
}
.coi-view .form-type {
  line-height: 40px;
}
.coi-view .form-type .type-name {
  text-transform: uppercase;
}
.coi-view .coi-member-header {
  cursor: pointer;
}
.coi-view table {
  border: 0;
}
.coi-view table thead tr {
  border-bottom: 0;
}
.coi-view table th {
  font-weight: bold;
  background-color: #e9e9e9;
  border-top: 0;
  border-bottom: 1px solid #bfbfbf !important;
}
.coi-view table th:first-child {
  min-width: 30%;
}
.coi-view table th,
.coi-view table td {
  padding: 0px 15px;
  border-width: 0;
  text-align: center;
  vertical-align: middle;
}
.coi-view table th:first-child,
.coi-view table td:first-child {
  text-align: left;
}
.coi-view table tr {
  height: 40px;
  background-color: #f2f2f2;
  border-bottom: 2px solid #fff;
}
.coi-view table tr:hover {
  background-color: #f7f7f7;
}
#toolbar-container menu[type="toolbar"].coi-view {
  width: 30px;
}
.coi-input-view {
  font-size: 16px;
}
.coi-input-view .questions {
  display: inline;
  float: left;
  overflow: hidden;
  width: 53.71900826446281%;
  margin: 0 0.413223140495868%;
  *width: 53.71900826446281% -correction;
  *margin: 0 0.413223140495868% -correction;
}
.coi-input-view .questions tbody tr:hover {
  background: #ddeaf9;
}
.coi-input-view .questions th,
.coi-input-view .questions td {
  padding: 5px;
}
.coi-input-view .questions th:last-child,
.coi-input-view .questions td:last-child {
  width: 60px;
  text-align: center;
  vertical-align: middle;
}
.coi-input-view .answers {
  display: inline;
  float: left;
  overflow: hidden;
  width: 90.08264462809917%;
  margin: 0 0.413223140495868%;
  *width: 90.08264462809917% -correction;
  *margin: 0 0.413223140495868% -correction;
  padding-left: 15px;
}
.coi-input-view .coi-input-footer {
  padding-top: 20px;
  clear: both;
}
.coi-input-view .coi-input-footer a {
  padding-top: 1em;
  float: left;
}
.coi-input-view .buttons-block {
  text-align: center;
}
.questions-with-conflicts .questions table {
  background-color: #fff;
}
.questions-with-conflicts .questions table th {
  text-align: center;
  border-bottom-width: 2px;
}
.questions-with-conflicts .questions table tr td {
  font-size: 14px;
}
.questions-with-conflicts .questions table th,
.questions-with-conflicts .questions table tr td {
  padding: 10px;
}
.questions-with-conflicts .answers ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.questions-with-conflicts .answers ul li {
  margin-bottom: 15px;
}
.panel-member-separator,
.separator {
  margin: 20px 50px 20px;
  background: #bfbfbf;
  height: 1px;
}
.organization-member-form-row {
  margin-bottom: 30px;
}
.organization-member-form-row h3 {
  margin: 0px 0px 10px;
}
.organization-member-form-row .access-rights .options-select-container {
  width: 130px;
}
.team-members-view label.required:after,
.add-team-member-modal label.required:after {
  content: " *";
}
.add-organization-member-modal.multiple .step1 .organization-members {
  max-height: 310px;
  overflow-y: auto;
}
.add-organization-member-modal.multiple .step1 .member-email {
  color: #999;
  font-size: 13px;
  padding: 0px 10px;
}
.add-organization-member-modal.multiple .step2 .members-list {
  max-height: 420px;
  overflow-y: auto;
}
.add-organization-member-modal label,
.add-team-member-modal label {
  font-weight: bold;
  cursor: default;
}
.add-organization-member-modal label.member-row-with-checkbox,
.add-team-member-modal label.member-row-with-checkbox {
  font-weight: normal;
  cursor: pointer;
}
.add-organization-member-modal.single h2,
.add-team-member-modal.single h2,
.add-organization-member-modal.multiple h2,
.add-team-member-modal.multiple h2 {
  margin-top: 0px;
  text-align: center;
}
.add-organization-member-modal.single .content,
.add-team-member-modal.single .content,
.add-organization-member-modal.multiple .content,
.add-team-member-modal.multiple .content {
  border: 0px;
  padding: 0px;
  background-color: #fff;
}
.add-organization-member-modal.single .form-group,
.add-team-member-modal.single .form-group,
.add-organization-member-modal.multiple .form-group,
.add-team-member-modal.multiple .form-group {
  position: relative;
}
.add-organization-member-modal.single .form-group label,
.add-team-member-modal.single .form-group label {
  margin-top: 0;
  line-height: 36px;
}
.add-organization-member-modal.single .form-group:after,
.add-team-member-modal.single .form-group:after {
  content: "";
  clear: both;
  height: 0;
  display: table;
}
.add-organization-member-modal.single .form-group .select-container,
.add-team-member-modal.single .form-group .select-container,
.add-organization-member-modal.single .form-group .textarea-container,
.add-team-member-modal.single .form-group .textarea-container,
.add-organization-member-modal.single .form-group .input-container,
.add-team-member-modal.single .form-group .input-container {
  width: 275px;
}
.add-organization-member-modal.single .form-group input,
.add-team-member-modal.single .form-group input,
.add-organization-member-modal.single .form-group select,
.add-team-member-modal.single .form-group select,
.add-organization-member-modal.single .form-group .select-input,
.add-team-member-modal.single .form-group .select-input {
  width: 100%;
  height: 36px;
}
.add-organization-member-modal.single .form-group textarea,
.add-team-member-modal.single .form-group textarea {
  width: 100%;
}
.add-organization-member-modal.single .select-with-annotations-container,
.add-team-member-modal.single .select-with-annotations-container,
.add-organization-member-modal.single .options-select-container,
.add-team-member-modal.single .options-select-container {
  width: 275px;
}
.add-organization-member-modal.single .select-with-annotations-container .text-box,
.add-team-member-modal.single .select-with-annotations-container .text-box,
.add-organization-member-modal.single .options-select-container .text-box,
.add-team-member-modal.single .options-select-container .text-box {
  line-height: 26px;
}
.add-organization-member-modal.multiple,
.add-team-member-modal.multiple {
  font-size: 14px;
  width: 790px;
}
.add-organization-member-modal.multiple .validation-container,
.add-team-member-modal.multiple .validation-container {
  padding-right: 25px;
}
.add-organization-member-modal.multiple .description,
.add-team-member-modal.multiple .description {
  margin: 10px 5px;
}
.add-organization-member-modal.multiple .textarea-container,
.add-team-member-modal.multiple .textarea-container {
  margin: 10px 0px 30px;
}
.add-organization-member-modal.multiple textarea,
.add-team-member-modal.multiple textarea {
  min-height: 100px;
  width: 100%;
}
.add-organization-member-modal.multiple .buttons-react,
.add-team-member-modal.multiple .buttons-react {
  padding-top: 10px;
}
.add-organization-member-modal.multiple .step2,
.add-team-member-modal.multiple .step2 {
  min-height: 230px;
  max-height: calc(100vh - 210px);
  padding-bottom: 10px;
  overflow-y: auto;
}
.add-organization-member-modal.multiple .new-member,
.add-team-member-modal.multiple .new-member {
  position: relative;
  padding-top: 10px;
  padding-right: 10px;
}
.add-organization-member-modal.multiple .new-member .content,
.add-team-member-modal.multiple .new-member .content {
  margin-top: 10px;
}
.add-organization-member-modal.multiple .new-member .panel-member,
.add-team-member-modal.multiple .new-member .panel-member {
  display: inline-block;
}
.add-organization-member-modal.multiple .new-member .delete,
.add-team-member-modal.multiple .new-member .delete {
  position: absolute;
  top: -10px;
  right: 10px;
  font-size: 36px;
  color: #808080;
  cursor: pointer;
}
.add-organization-member-modal.multiple .new-member .delete:before,
.add-team-member-modal.multiple .new-member .delete:before {
  content: '\00d7';
}
.add-organization-member-modal.multiple .new-member:last-child .separator,
.add-team-member-modal.multiple .new-member:last-child .separator {
  display: none;
}
.add-organization-member-modal.multiple .form-group,
.add-team-member-modal.multiple .form-group {
  display: inline-block;
}
.add-organization-member-modal.multiple .form-group.panel-member .info-sign,
.add-team-member-modal.multiple .form-group.panel-member .info-sign {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  top: -10px;
}
.add-organization-member-modal.multiple .form-group.panel-member input[type="checkbox"],
.add-team-member-modal.multiple .form-group.panel-member input[type="checkbox"] {
  margin-top: 7px;
}
.add-organization-member-modal.multiple .form-group .inline label,
.add-team-member-modal.multiple .form-group .inline label {
  position: relative;
  margin-left: 5px;
  width: 140px;
  line-height: 30px;
  font-weight: bold;
}
.add-organization-member-modal.multiple .form-group .inline .options-select-container,
.add-team-member-modal.multiple .form-group .inline .options-select-container,
.add-organization-member-modal.multiple .form-group .inline .select-input,
.add-team-member-modal.multiple .form-group .inline .select-input,
.add-organization-member-modal.multiple .form-group .inline input[type="text"],
.add-team-member-modal.multiple .form-group .inline input[type="text"] {
  height: 30px;
  width: 175px;
  font-size: 14px;
}
.add-organization-member-modal.multiple .form-group .inline .select-input .text-box,
.add-team-member-modal.multiple .form-group .inline .select-input .text-box {
  padding: 2px 5px;
}
.add-organization-member-modal.multiple .form-group .inline .select-input .select-sign,
.add-team-member-modal.multiple .form-group .inline .select-input .select-sign {
  padding: 0px 5px;
}
.add-organization-member-modal .buttons-react.not-wrapped,
.add-team-member-modal .buttons-react.not-wrapped {
  text-align: center;
}
.add-organization-member-modal .buttons-react.not-wrapped button,
.add-team-member-modal .buttons-react.not-wrapped button {
  display: inline-block !important;
  width: auto !important;
  min-width: 150px;
  margin: 0px 10px;
}
.add-organization-member-modal {
  width: 650px !important;
}
.add-organization-member-modal .form-group {
  display: block;
  width: 100%;
}
.add-organization-member-modal .form-group .inline {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.add-organization-member-modal .form-group .inline:not(:last-child) {
  margin-bottom: 5px;
}
.add-organization-member-modal .form-group .inline label {
  font-weight: normal !important;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 145px;
  flex-basis: 145px;
  width: 145px;
}
.add-organization-member-modal .form-group .inline .validation-container {
  display: inline-block;
  padding-right: 0;
}
.add-organization-member-modal .other-members input[type="text"],
.add-organization-member-modal .other-members .select-input,
.add-organization-member-modal .other-members .select-container,
.add-organization-member-modal .other-members .options-select-container {
  width: 100% !important;
}
.add-organization-member-modal .access-rights .select-option-annotation {
  max-width: 230px;
}
.validation-container {
  position: relative;
}
.team-member-toolbar {
  margin-bottom: 10px;
}
.team-member-toolbar .filter-by,
.team-member-toolbar button.add-multiple-team-member,
.team-member-toolbar button.import-team-members {
  margin-left: 10px;
}
.single label,
.editable-name-cell label {
  font-weight: bold;
  cursor: default;
}
.single .form-group,
.editable-name-cell .form-group {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 26px;
}
.single .form-group input,
.editable-name-cell .form-group input {
  height: 28px;
  line-height: 28px;
}
.single .form-group input,
.editable-name-cell .form-group input,
.single .form-group textarea,
.editable-name-cell .form-group textarea,
.single .form-group .options-select-container,
.editable-name-cell .form-group .options-select-container {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  width: 200px;
  color: #5c5d5f;
}
.single .form-group input[type="checkbox"],
.editable-name-cell .form-group input[type="checkbox"] {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.single .form-group textarea,
.editable-name-cell .form-group textarea {
  padding: 5px 10px;
  line-height: 14px;
  height: 82px;
}
.single .form-group label,
.editable-name-cell .form-group label {
  width: 140px;
  margin-left: 5px;
}
.single .form-group .options-select-container,
.editable-name-cell .form-group .options-select-container {
  border: none;
}
.single .form-group .options-select-container {
  width: 100%;
  height: 36px;
}
.single .form-group .options-select-container .select-input {
  line-height: 34px;
  height: 36px;
}
.single .form-group .options-select-container .select-input .select-sign {
  font-size: 12px;
  padding: 0px 5px;
  line-height: 36px;
}
.single .form-group .options-select-container {
  border: none;
}
.single .form-group label {
  position: relative;
}
.single .form-group label .info-sign {
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -10px;
}
.team-members-view .member-name {
  color: #2d3236;
  text-decoration: none;
  position: relative;
  padding-right: 30px;
  padding-left: 10px;
}
.team-members-view .member-name:hover {
  color: #2d3236;
}
.team-members-view .member-name:hover:after {
  position: absolute;
  top: 3px;
  content: '';
  width: 16px;
  height: 12px;
  margin-left: 10px;
  background: url("../images/mail.png") top left no-repeat;
  text-indent: -9999px;
}
.team-members-view label {
  font-weight: bold;
  cursor: default;
}
.team-members-view .options-select-container {
  height: 28px;
  width: 200px;
}
.team-members-view .options-select-container .select-input {
  height: 28px;
}
.team-members-view .options-select-container .select-input .text-box,
.team-members-view .options-select-container .select-input .select-sign {
  padding: 0px 5px;
  line-height: 26px;
}
.team-members-view .options-select-container .select-input .select-sign {
  font-size: 12px;
}
.team-members-view .form-group {
  position: relative;
}
.panel-member input[type="checkbox"],
.roles input[type="checkbox"] {
  margin-right: 10px;
}
.panel-member label {
  cursor: pointer;
  font-weight: normal;
}
.team-members-list {
  height: calc(100vh - 175px);
  overflow-y: auto;
  overflow-x: hidden;
}
table.team-members-table {
  width: 100%;
}
table.team-members-table th:first-child {
  padding-left: 10px;
}
table.team-members-table th,
table.team-members-table td {
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #bfbfbf;
}
table.team-members-table td {
  border-bottom: 2px solid #fff;
}
table.team-members-table td.checkbox,
table.team-members-table td.controls {
  text-align: center;
  width: 40px;
  border-left: 2px solid #fff;
}
table.team-members-table td.controls {
  vertical-align: middle;
}
table.team-members-table td.controls .control {
  height: 36px;
}
table.team-members-table td.controls .control button {
  line-height: 36px;
}
table.team-members-table td.name {
  width: auto;
}
table.team-members-table td.name label {
  font-weight: bold;
  cursor: default;
}
table.team-members-table td.access-rights {
  width: 35%;
}
table.team-members-table td.panel-member {
  width: 25%;
}
table.team-members-table tr {
  background-color: #f2f2f2;
  height: 40px;
}
table.team-members-table tr:hover {
  background-color: #f7f7f7;
}
table.team-members-table tr.expanded td {
  vertical-align: top;
  padding: 10px 0px;
  background: #f6f6f6;
}
table.team-members-table tr.expanded td.controls {
  padding-top: 0;
}
table.team-members-table td {
  font-size: 14px;
}
table.team-members-table thead tr,
table.team-members-table thead tr:hover {
  background-color: #fff;
}
table.team-members-table thead tr th .with-info,
table.team-members-table thead tr:hover th .with-info {
  position: relative;
  padding-right: 20px;
}
table.team-members-table thead tr th .sortable-wrapper,
table.team-members-table thead tr:hover th .sortable-wrapper {
  cursor: pointer;
}
table.team-members-table thead tr th .sort,
table.team-members-table thead tr:hover th .sort {
  position: relative;
  display: inline-block;
  top: -2px;
  margin-left: 2px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table.team-members-table thead tr th .sort.asc,
table.team-members-table thead tr:hover th .sort.asc {
  border-bottom: 5px solid;
}
table.team-members-table thead tr th .sort.desc,
table.team-members-table thead tr:hover th .sort.desc {
  border-top: 5px solid;
}
.select-with-annotations-container.member-access-rights {
  width: 175px;
  position: relative;
}
.select-option-annotation.member-access-annotation {
  z-index: 112;
  max-width: 350px;
  left: 175px;
  padding: 5px;
  color: #fff;
  background-color: #000048;
  border: none;
}
.select-option-annotation.member-access-annotation:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #000048;
  position: absolute;
  bottom: 0px;
  left: -10px;
}
.limits-container .btn-icon.info-icon {
  position: relative;
  top: -5px;
}
.limits-warning {
  background: #fff8de;
  padding: 2px 5px;
  margin: 2px 0px 5px;
}
.add-new-member-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.members-limits .members-limits__container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 5px;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.panel-members-import-modal .ep-modal__content {
  width: 500px;
  min-height: 200px;
}
.panel-members-import-modal .search-box {
  margin: 14px 0;
}
.panel-members-import-modal .select-all {
  font-weight: bold;
}
.panel-members-import-modal .select-all .item-with-checkbox__item {
  margin-left: 10px;
}
.panel-members-import-modal .panel-members {
  max-height: 200px;
  overflow-y: auto;
  margin: 0 0 14px;
}
.panel-members-import-modal .panel-members .item-with-checkbox {
  margin: 5px 0;
}
.panel-members-import-modal .panel-members .item-with-checkbox .item-with-checkbox__item {
  background: #f2f2f2;
  padding: 5px 10px;
  margin-left: 10px;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}
.panel-members-import-modal .panel-members .item-with-checkbox:hover .item-with-checkbox__item {
  background: #f7f7f7;
}
