@font-face {
  font-family: 'Poppins';
  src: local(""), url("./../fonts/poppins/poppins-v15-latin-ext_latin-300.woff2") format("woff2"), url("./../fonts/poppins/poppins-v15-latin-ext_latin-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local(""), url("./../fonts/poppins/poppins-v15-latin-ext_latin-400.woff2") format("woff2"), url("./../fonts/poppins/poppins-v15-latin-ext_latin-400.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local(""), url("./../fonts/poppins/poppins-v15-latin-ext_latin-600.woff2") format("woff2"), url("./../fonts/poppins/poppins-v15-latin-ext_latin-600.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local(""), url("./../fonts/poppins/poppins-v15-latin-ext_latin-700.woff2") format("woff2"), url("./../fonts/poppins/poppins-v15-latin-ext_latin-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
