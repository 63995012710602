@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.buttons-row .button-container {
  position: relative;
  min-height: 1px;
  padding: 0 5px;
  float: left;
}
.buttons-row.sticked-bottom {
  position: fixed;
  bottom: 10px;
  padding-top: 5px;
  background-color: #fff;
}
.items-counter {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  width: auto;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.items-counter .tooltip {
  position: relative;
  margin-left: 10px;
}
.items-counter .tooltip:before {
  font-family: 'gdt';
  content: '\e066';
  color: #3783a5;
}
.items-counter h3 {
  line-height: 35px;
  margin: 0px;
  padding: 0px 10px;
}
.items-counter .items-counter__pill {
  color: #fff;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  line-height: 18px;
  padding: 0px 15px 1px;
  background-color: #009cca;
}
.items-counter .items-counter__pill.items-counter__pill--exceeded {
  background-color: #f67c61;
}
.module-progress-select {
  position: relative;
  font-size: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.module-progress-select span.bold {
  margin-right: 10px;
  font-weight: bold;
}
.module-progress-select .module-progress__box {
  display: inline-block;
  padding: 0px 15px;
  height: 20px;
  line-height: 16px;
  min-width: 110px;
  cursor: pointer;
  text-align: center;
}
.radio-with-label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.input-with-controls {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 10px 15px;
  text-align: center;
  background-color: #275c74;
  color: #fff;
}
.input-with-controls:hover .input-with-controls__controls {
  display: block;
}
.input-with-controls input {
  width: 100%;
  line-height: 38px;
}
.input-with-controls .input-with-controls__controls {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}
.input-with-controls .input-with-controls__controls button:before {
  color: #fff;
}
.progress-bar {
  background: #ddd;
  width: 75%;
  height: 20px;
  position: relative;
}
.progress-bar .progress-bar__inner {
  background: #0089ba;
  height: 100%;
}
.progress-bar .progress-bar__text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.item-with-checkbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.item-with-checkbox:not(.item-with-checkbox.readOnly):hover {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.item-with-checkbox .item-with-checkbox__checkbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.item-with-checkbox .item-with-checkbox__checkbox input {
  cursor: pointer;
}
.item-with-checkbox .item-with-checkbox__item {
  padding-left: 5px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.questions-selector {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.questions-selector .questions-selector__search {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #bfbfbf;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.questions-selector .questions-selector__search.without-border {
  border-bottom: none;
}
.questions-selector .questions-selector__search .search__select-all {
  margin-right: 10px;
}
.questions-selector .questions-selector__search .search__select-all .item-with-checkbox__checkbox {
  margin-right: 6px;
}
.questions-selector .questions-selector__search .search__input {
  width: 30%;
}
.questions-selector .questions-selector__questions {
  overflow-y: auto;
  max-height: 380px;
  counter-reset: questionItem;
}
.questions-selector .questions-selector__questions .item-with-checkbox.fake .item-with-checkbox__checkbox {
  padding: 0 !important;
  margin-right: 17px;
}
.questions-selector .questions-selector__questions .item-with-checkbox .group-caption {
  font-weight: bold;
  padding: 5px 10px;
}
.questions-selector .questions-selector__questions .item-with-checkbox .recommendation-row {
  padding: 0 10px;
  min-height: 40px;
  margin-bottom: 2px;
  background: #f6f6f6;
}
.questions-selector .questions-selector__questions .item-with-checkbox .recommendation-row .gdt-editor-container {
  border: none;
}
.questions-selector .questions-selector__questions .item-with-checkbox .recommendation-row .gdt-editor-container .public-DraftEditor-content {
  min-height: 20px;
}
.questions-selector .questions-selector__questions .item-with-checkbox .question-row {
  padding: 0 10px;
  margin-bottom: 2px;
  background: #f6f6f6;
  height: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.questions-selector .questions-selector__questions .item-with-checkbox .question-row.readOnly {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.questions-selector .questions-selector__questions .item-with-checkbox .question-row.with-numbers {
  padding-left: 50px;
  position: relative;
}
.questions-selector .questions-selector__questions .item-with-checkbox .question-row.with-numbers:before {
  width: 40px;
  line-height: 40px;
  content: counter(questionItem);
  counter-increment: questionItem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 2px solid #fff;
  text-align: center;
}
.questions-selector .questions-selector__questions .item-with-checkbox .question-row.overarching-question {
  background: #fff8de;
}
.questions-selector .questions-selector__questions .item-with-checkbox.border .item-with-checkbox__item {
  border-top: 1px solid #009cca;
  border-left: 1px solid #009cca;
  border-right: 1px solid #009cca;
}
.questions-selector .questions-selector__questions .group-container {
  margin-bottom: 10px;
}
.questions-selector .questions-selector__questions .group-container .questions-list .item-with-checkbox__item {
  padding: 0 5px;
  border-left: 1px solid #009cca;
  border-right: 1px solid #009cca;
}
.questions-selector .questions-selector__questions .group-container .item-with-checkbox__checkbox {
  padding-right: 5px;
}
.questions-selector .questions-selector__questions .group-container .bottom-border {
  width: 100%;
  border-bottom: 1px solid #009cca;
  border-left: 1px solid #009cca;
  border-right: 1px solid #009cca;
  padding-top: 5px;
}
.questions-selector .questions-selector__questions .questions-without-group .item-with-checkbox__item {
  padding: 0 6px 0 11px;
}
.date-picker-component {
  position: relative;
}
.date-picker-component .date-picker-component__inner {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.date-picker-component .date-picker-component__inner button.date-pick {
  background-color: #cecece;
  height: 32px;
  width: 28px;
}
.date-picker-component .date-picker-component__inner button.date-pick:after {
  font-family: 'gdt';
  content: '\e010';
  text-align: center;
}
.date-picker-component .DayPicker-Container {
  position: absolute;
  display: block;
  left: 0;
  top: 31px;
  width: 275px;
  background-color: #eaeaea;
  border: 1px solid #cecece;
  z-index: 99;
}
.date-picker-component .DayPicker-Container .DayPicker {
  width: 100%;
}
.date-picker-component .DayPicker-Container .DayPicker-Day--disabled {
  background-color: inherit;
  color: #cecece;
}
.date-picker-component .DayPicker-Container .DayPicker-NavBar span {
  top: 5px;
}
.date-picker-component .DayPicker-Container .DayPicker-Month {
  min-width: 200px;
}
.date-picker-component .DayPicker-Container .DayPicker-Caption {
  height: 30px;
}
.date-picker-component .DayPicker-Container .DayPicker-Caption select {
  background-color: #fff;
}
.overarching-questions-creator .question-creator .question-creator__form .extraction-form {
  min-width: 1180;
  max-width: 1400px;
}
.question-creator {
  font-size: 14px;
}
.question-creator input {
  font-size: 14px;
}
.question-creator .question-creator__form {
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 0 5px;
}
.question-creator .question-creator__form .extraction-field__value.questionTags {
  -webkit-flex: 1 0 100%;
  -ms-flexbox: 1 0 100%;
  flex: 1 0 100%;
  max-width: 100%;
}
.question-creator .question-creator__form .extraction-field__value.questionTags .question-tags__question-tag .question-tag__label {
  width: 220px;
  text-align: right;
}
.question-creator .question-creator__form .extraction-field__value.questionTags .question-tags__question-tag .question-tag__tags input {
  width: 400px;
}
.question-creator .buttons-react {
  margin-top: 20px;
}
.question-creator label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.question-creator label .label-text {
  width: 200px;
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
}
.question-creator label input {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.question-creator hr {
  border-color: #009cca;
}
.question-creator .options-select-container .select-input {
  border-color: #3883a5;
}
.question-creator .options-select-container,
.question-creator .ep-select-with-checkboxes__trigger {
  width: 200px;
  height: 35px;
  border-color: #3883a5;
}
.question-creator .options-select-container .ep-expand-button,
.question-creator .ep-select-with-checkboxes__trigger .ep-expand-button {
  margin-top: 0;
  margin-right: 0;
}
.question-creator .options-select-container .ep-expand-button svg,
.question-creator .ep-select-with-checkboxes__trigger .ep-expand-button svg {
  fill: #3883a5;
}
.question-creator .question-creator__form {
  max-height: calc(100vh - 205px);
  overflow: auto;
}
.question-creator .question-creator__form .form__select {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.question-creator .selected-question-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.question-creator .selected-question-row .selected-question {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-right: 10px;
}
.question-creator .selected-question-row .question-no {
  font-weight: bold;
  width: 190px;
  white-space: nowrap;
  margin-right: 20px;
}
.question-creator .question-creator__buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 50px;
}
.question-creator .question-creator__buttons button {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
  margin-right: 10px;
}
.question-creator .question-creator__buttons button:last-child {
  margin-right: 0px;
}
.question-creator .question-creator__buttons button .ep-btn-label {
  width: 100%;
  text-align: center;
}
.popover-codes-list {
  margin-top: 10px;
  list-style-type: none;
  padding: 0;
  max-height: 300px;
  max-width: 600px;
  overflow: auto;
}
.popover-codes-list .codes-list__code {
  cursor: pointer;
  padding: 0px;
  line-height: 25px;
  margin-bottom: 3px;
}
.popover-codes-list .codes-list__code.included,
.popover-codes-list .codes-list__code .highlight {
  background: #f7bf9c;
}
.popover-codes-list .codes-list__code:hover {
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}
.question-tags {
  padding-top: 10px;
}
.question-tags .question-tags__question-tag {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.question-tags .question-tags__question-tag .question-tag__label {
  padding-top: 6px;
  width: 200px;
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
}
.question-tags .question-tags__question-tag .question-tag__tags {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__tag-item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__tag-item .tag-item__name input {
  width: 400px;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__tag-item .tag-item__delete-tag-item {
  margin-left: 10px;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__tag-item .tag-item__delete-tag-item button:before {
  font-size: 16px;
  color: #c3c3c3;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__tag-item .tag-item__codes {
  margin-left: 10px;
  max-width: 450px;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__tag-item .tag-item__codes .code-label {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__tag-item .tag-item__codes .add-code-trigger:not(:first-child) {
  margin-left: 10px;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__add-tag-item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 400px;
}
.question-tags .question-tags__question-tag .question-tag__tags .tags__add-tag-item .label-text {
  font-size: 14px;
}
