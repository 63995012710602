@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.outcomes-container {
  overflow-y: auto;
  font-size: 14px;
}
.outcomes-container .sticky-header {
  -webkit-box-shadow: 0px 3px 3px rgba(0,0,0,0.3);
  box-shadow: 0px 3px 3px rgba(0,0,0,0.3);
  margin-bottom: 5px;
  border-bottom: 1px solid #29abe2;
}
.outcomes-container .context-help-icon:before {
  position: absolute;
  top: 5px;
  right: 5px;
  line-height: 13px;
  color: #3783a5;
  font-size: 13px;
  cursor: pointer;
}
.outcomes-container .footnote-active {
  background-color: #f4e39e;
}
.outcomes-container button.footnote-required {
  position: absolute;
  height: 17px;
  width: 16px;
  font-size: 12px;
  bottom: 0;
  right: 0;
}
.outcomes-container button.footnote-required,
.outcomes-container button.footnote-required:link,
.outcomes-container button.footnote-required:visited,
.outcomes-container button.footnote-required:hover,
.outcomes-container button.footnote-required:active {
  color: inherit;
  text-decoration: none;
}
.outcomes-container button.footnote-required:before {
  font-family: 'gdt';
  content: '\e074';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.outcomes-container button.footnote-required * {
  margin-left: 0.5rem;
}
.outcomes-container button.footnote-required:before {
  color: #f67c61;
}
.outcomes-container .abs-effect-auto-calc .sup {
  width: 20px;
  height: 16px;
  position: relative;
}
.outcomes-container input[type="text"] {
  height: 30px;
  font-size: 14px;
  line-height: 16px;
}
.outcomes-container .search-container input[type=text] {
  line-height: 36px;
  height: 38px;
}
.outcomes-container table.isof-table th {
  color: #2d3236;
}
.outcomes-container table.standard-table th,
.outcomes-container table.standard-table td {
  border: 3px solid #fff;
  font-size: 13px;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.outcomes-container table th {
  padding: 5px 0;
  position: relative;
  background-color: #3783a5;
  color: #fff;
}
.outcomes-container table th.risk-with-control,
.outcomes-container table th.risk-difference-with-intervention,
.outcomes-container table th.intervention,
.outcomes-container table th.control,
.outcomes-container table th.corresponding-risk,
.outcomes-container table th.without-intervention {
  padding: 5px 20px;
}
.outcomes-container table th.importance {
  padding: 0;
}
.outcomes-container table th .context-help-icon:before {
  color: #fff;
}
.outcomes-container .sticky-header > table.narrative tr.outcome-sof-row .impact,
.outcomes-container table.outcomes-table.narrative tr.outcome-sof-row .impact,
.outcomes-container .sticky-header > table.narrative tr.outcome-sof-row-v4 .impact,
.outcomes-container table.outcomes-table.narrative tr.outcome-sof-row-v4 .impact {
  width: 36%;
}
.outcomes-container .sticky-header > table td:not(.outcome-label-row),
.outcomes-container table.outcomes-table td:not(.outcome-label-row) {
  cursor: pointer;
  position: relative;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background-color: #f2f2f2;
  text-align: center;
}
.outcomes-container .sticky-header > table td:not(.outcome-label-row):hover,
.outcomes-container table.outcomes-table td:not(.outcome-label-row):hover {
  background-color: #ddeaf9;
}
.outcomes-container .sticky-header > table td:not(.outcome-label-row) hr,
.outcomes-container table.outcomes-table td:not(.outcome-label-row) hr {
  padding: 0;
  margin: 0;
}
.outcomes-container .sticky-header > table td:not(.outcome-label-row) .quality-sign,
.outcomes-container table.outcomes-table td:not(.outcome-label-row) .quality-sign {
  color: #3883a5;
}
.outcomes-container .sticky-header > table .outcome-row.editing td,
.outcomes-container table.outcomes-table .outcome-row.editing td {
  background-color: #d2dfed;
}
.outcomes-container .sticky-header > table .outcome-row.editing button.save,
.outcomes-container table.outcomes-table .outcome-row.editing button.save {
  display: inline-block;
}
.outcomes-container .sticky-header > table .outcome-row.editing button.edit,
.outcomes-container table.outcomes-table .outcome-row.editing button.edit {
  display: none;
}
.outcomes-container .sticky-header > table .outcome-row:not(.editing) button.save,
.outcomes-container table.outcomes-table .outcome-row:not(.editing) button.save {
  display: none;
}
.outcomes-container .sticky-header > table .outcome-row:not(.editing) button.edit,
.outcomes-container table.outcomes-table .outcome-row:not(.editing) button.edit {
  display: inline-block;
}
.outcomes-container .sticky-header > table .outcome-row td,
.outcomes-container table.outcomes-table .outcome-row td {
  word-break: break-word;
}
.outcomes-container .sticky-header > table .edit-sof-outcome,
.outcomes-container table.outcomes-table .edit-sof-outcome {
  width: 33px;
  vertical-align: middle;
}
.outcomes-container .sticky-header > table .edit-sof-outcome button.edit,
.outcomes-container table.outcomes-table .edit-sof-outcome button.edit {
  border-left: none;
}
.outcomes-container .sticky-header > table .edit-sof-outcome button.save,
.outcomes-container table.outcomes-table .edit-sof-outcome button.save {
  display: none;
}
.outcomes-container .sticky-header > table td.control-label,
.outcomes-container table.outcomes-table td.control-label {
  font-weight: bold;
  padding: 5px 0px;
  height: auto;
  background-color: #f2f2f2;
}
.outcomes-container .sticky-header > table td.edit-sof-outcome,
.outcomes-container table.outcomes-table td.edit-sof-outcome {
  background-color: #f2f2f2;
}
.outcomes-container .sticky-header > table td.warning,
.outcomes-container table.outcomes-table td.warning {
  background-color: #f7bf9c;
}
.outcomes-container .sticky-header > table .quality,
.outcomes-container table.outcomes-table .quality {
  text-align: center;
}
.outcomes-container .sticky-header > table .absolute-difference td,
.outcomes-container table.outcomes-table .absolute-difference td,
.outcomes-container .sticky-header > table td.absolute-effect,
.outcomes-container table.outcomes-table td.absolute-effect,
.outcomes-container .sticky-header > table td.corresponding-risk,
.outcomes-container table.outcomes-table td.corresponding-risk,
.outcomes-container .sticky-header > table td.risk-difference-with-intervention,
.outcomes-container table.outcomes-table td.risk-difference-with-intervention {
  background-color: #ffeccb;
}
.outcomes-container .sticky-header > table td.diagnostic-outcome-label,
.outcomes-container table.outcomes-table td.diagnostic-outcome-label,
.outcomes-container .sticky-header > table td.diagnostic-effect,
.outcomes-container table.outcomes-table td.diagnostic-effect,
.outcomes-container .sticky-header > table .outcome-diag-row td.quality,
.outcomes-container table.outcomes-table .outcome-diag-row td.quality {
  background-color: #f2f2f2;
}
.outcomes-container .sticky-header > table td.narrative-desc,
.outcomes-container table.outcomes-table td.narrative-desc {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.outcomes-container .sticky-header > table td.narrative-desc ul,
.outcomes-container table.outcomes-table td.narrative-desc ul {
  margin-left: 0;
  padding-left: 20px;
}
.outcomes-container .sticky-header > table .inner-cell,
.outcomes-container table.outcomes-table .inner-cell {
  padding: 2px 3px;
}
.outcomes-container .sticky-header > table .cell-content,
.outcomes-container table.outcomes-table .cell-content {
  min-height: 30px;
  display: inline-block;
  padding: 2px 3px;
  word-break: break-all;
}
.outcomes-container .sticky-header > table .cell-content sup,
.outcomes-container table.outcomes-table .cell-content sup {
  display: inline-block;
}
.outcomes-container .sticky-header > table .inner-cell .cell-content,
.outcomes-container table.outcomes-table .inner-cell .cell-content {
  padding: 0;
}
.outcomes-container .sticky-header > table .active,
.outcomes-container table.outcomes-table .active {
  background-color: #d2dfed !important;
}
.outcomes-container .sticky-header > table .active.footnote-active,
.outcomes-container table.outcomes-table .active.footnote-active {
  background-color: #f4e39e !important;
}
.outcomes-container .sticky-header > table .not-editable-cell,
.outcomes-container table.outcomes-table .not-editable-cell,
.outcomes-container .sticky-header > table .disabled-cell,
.outcomes-container table.outcomes-table .disabled-cell {
  cursor: default;
  background-color: #ccc;
}
.outcomes-container .sticky-header > table .outcome-label,
.outcomes-container table.outcomes-table .outcome-label {
  line-height: 30px;
  text-align: left;
  background: #fff;
  height: 30px;
}
.outcomes-container .sticky-header > table .outcome-label .ellipsis-wrapper,
.outcomes-container table.outcomes-table .outcome-label .ellipsis-wrapper {
  display: inline-block;
}
.outcomes-container .sticky-header > table .outcome-label .outcome-name,
.outcomes-container table.outcomes-table .outcome-label .outcome-name,
.outcomes-container .sticky-header > table .outcome-label .reviewed-outcome-name,
.outcomes-container table.outcomes-table .outcome-label .reviewed-outcome-name {
  margin: 0px 5px;
  font-weight: bold;
}
.outcomes-container .sticky-header > table .outcome-cell,
.outcomes-container table.outcomes-table .outcome-cell {
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: bold;
}
.outcomes-container .sticky-header > table button.sort,
.outcomes-container table.outcomes-table button.sort,
.outcomes-container .sticky-header > table button.save,
.outcomes-container table.outcomes-table button.save,
.outcomes-container .sticky-header > table button.edit,
.outcomes-container table.outcomes-table button.edit,
.outcomes-container .sticky-header > table button.delete,
.outcomes-container table.outcomes-table button.delete,
.outcomes-container .sticky-header > table button.add-evidence-stream,
.outcomes-container table.outcomes-table button.add-evidence-stream {
  width: 30px;
  height: 30px;
  float: right;
  font-size: 16px;
  background-position: center center;
}
.outcomes-container .sticky-header > table button.add-evidence-stream,
.outcomes-container table.outcomes-table button.add-evidence-stream {
  background: url("../images/plus.png") right 8px no-repeat;
}
.outcomes-container menu.buttons.disabled {
  cursor: default;
  background-color: rgba(206,206,206,0.2);
  color: rgba(45,50,54,0.4);
}
.outcomes-container .absolute-difference td {
  height: 20px;
  line-height: 20px;
}
.outcomes-container .outcome-label-row {
  background-color: #fff;
  padding-top: 5px;
}
.outcomes-container .outcome-label-row:hover {
  background: initial;
}
.outcomes-container .outcome-label-row.edit-label-state {
  border-right: 0 !important;
}
.outcomes-container .outcome-label-row .outcome-label div.name-block {
  padding-right: 30px;
}
.outcomes-container .outcome-label-row div.name-block {
  overflow: hidden;
}
.outcomes-container .select-outcome-type-warning {
  color: #f00;
}
.outcomes-container .outcome-edit-block {
  display: none;
}
.outcomes-container .force-type {
  background-color: #f7bf9c;
}
.outcomes-container th button.table-mode:before {
  color: #fff;
}
.outcomes-container tr.outcome-one-row .table-mode,
.outcomes-container tr.outcome-sof-row .table-mode,
.outcomes-container tr.outcome-accp-row .table-mode,
.outcomes-container tr.outcome-sof-accp-row .table-mode {
  width: 30px;
}
.outcomes-container tr.outcome-one-row > th {
  word-break: break-word;
}
.outcomes-container tr.outcome-one-row .no-of-studies {
  width: 5%;
}
.outcomes-container tr.outcome-one-row .design-studies {
  width: 8%;
}
.outcomes-container tr.outcome-one-row .risk-of-bias {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .inconsistency {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .indirectness {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .imprecision {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .no-of-patients {
  width: 16%;
}
.outcomes-container tr.outcome-one-row .relative-effect,
.outcomes-container tr.outcome-one-row .intervention,
.outcomes-container tr.outcome-one-row .control {
  width: 8%;
}
.outcomes-container tr.outcome-one-row .other-considerations {
  width: 11%;
}
.outcomes-container tr.outcome-one-row .quality {
  width: 6%;
}
.outcomes-container tr.outcome-one-row .importance {
  width: 10%;
}
.outcomes-container tr.outcome-sof-row-v4 .outcome-cell,
.outcomes-container tr.outcome-sof-row .outcome-cell {
  width: auto;
}
.outcomes-container tr.outcome-sof-row-v4 .control,
.outcomes-container tr.outcome-sof-row .control,
.outcomes-container tr.outcome-sof-row-v4 .intervention,
.outcomes-container tr.outcome-sof-row .intervention,
.outcomes-container tr.outcome-sof-row-v4 .difference,
.outcomes-container tr.outcome-sof-row .difference,
.outcomes-container tr.outcome-sof-row-v4 .with-intervention,
.outcomes-container tr.outcome-sof-row .with-intervention,
.outcomes-container tr.outcome-sof-row-v4 .without-intervention,
.outcomes-container tr.outcome-sof-row .without-intervention,
.outcomes-container tr.outcome-sof-row-v4 .risk-with-intervention,
.outcomes-container tr.outcome-sof-row .risk-with-intervention,
.outcomes-container tr.outcome-sof-row-v4 .risk-difference-with-intervention,
.outcomes-container tr.outcome-sof-row .risk-difference-with-intervention,
.outcomes-container tr.outcome-sof-row-v4 .risk-with-control,
.outcomes-container tr.outcome-sof-row .risk-with-control,
.outcomes-container tr.outcome-sof-row-v4 .corresponding-risk,
.outcomes-container tr.outcome-sof-row .corresponding-risk,
.outcomes-container tr.outcome-sof-row-v4 .relative-effect,
.outcomes-container tr.outcome-sof-row .relative-effect,
.outcomes-container tr.outcome-sof-row-v4 .no-of-participants,
.outcomes-container tr.outcome-sof-row .no-of-participants,
.outcomes-container tr.outcome-sof-row-v4 .quality,
.outcomes-container tr.outcome-sof-row .quality {
  width: 12%;
}
.outcomes-container tr.outcome-sof-row-v4 .what_happens,
.outcomes-container tr.outcome-sof-row .what_happens,
.outcomes-container tr.outcome-sof-row-v4 .comments,
.outcomes-container tr.outcome-sof-row .comments {
  width: 26%;
}
.outcomes-container tr.outcome-sof-row-v4 .impact,
.outcomes-container tr.outcome-sof-row .impact {
  width: 48%;
}
.outcomes-container tr.outcome-sof-row-v4 .patient-studies,
.outcomes-container tr.outcome-sof-row .patient-studies {
  width: 5%;
}
.outcomes-container tr.outcome-accp-row .participants {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .risk-of-bias {
  width: 6%;
}
.outcomes-container tr.outcome-accp-row .inconsistency {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .indirectness {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .imprecision {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .other-considerations {
  width: 10%;
}
.outcomes-container tr.outcome-accp-row .quality {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .case-control {
  width: 16%;
}
.outcomes-container tr.outcome-accp-row .event-rates-with-control {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .event-rates-with-intervention {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .relative-effect {
  width: 10%;
}
.outcomes-container tr.outcome-accp-row .risk-with-control,
.outcomes-container tr.outcome-accp-row td:nth-child(11) {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .risk-difference-with-intervention {
  width: auto;
}
.outcomes-container tr.outcome-sof-accp-row .outcome-cell {
  width: auto;
}
.outcomes-container tr.outcome-sof-accp-row .no-of-participants {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .quality {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .relative-effect {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .risk-with-control,
.outcomes-container tr.outcome-sof-accp-row .control {
  width: 12%;
}
.outcomes-container tr.outcome-sof-accp-row .absolute-effect {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .risk-difference-with-intervention {
  width: 12%;
}
.outcomes-container tr.outcome-sof-accp-row .impact {
  width: 34%;
}
.outcomes-container tr.outcome-diag-row th.diagnostic-effect,
.outcomes-container tr.outcome-diag-sof-row th.diagnostic-effect {
  word-break: break-word;
}
.outcomes-container tr.outcome-diag-row > th {
  word-break: break-word;
}
.outcomes-container tr.outcome-diag-row .diagnostic-outcome-label {
  width: auto;
}
.outcomes-container tr.outcome-diag-row .no-of-studies-patients {
  width: 6%;
}
.outcomes-container tr.outcome-diag-row .design-studies {
  width: 6%;
}
.outcomes-container tr.outcome-diag-row .risk-of-bias,
.outcomes-container tr.outcome-diag-row .indirectness,
.outcomes-container tr.outcome-diag-row .imprecision {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .inconsistency {
  min-width: 88px;
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .publication-bias {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .other-considerations {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .diagnostic-effect {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .quality {
  width: 6%;
}
.outcomes-container tr.outcome-diag-row .importance {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row th.table-mode {
  width: 2%;
}
.outcomes-container tr.outcome-diag-row td.quality.wider {
  width: 8%;
}
.outcomes-container tr.outcome-diag-row td.importance.wider {
  width: 9%;
}
.outcomes-container tr.outcome-diag-sof-row .diagnostic-outcome-label {
  width: 20%;
}
.outcomes-container tr.outcome-diag-sof-row .no-of-participants-studies {
  width: 7%;
}
.outcomes-container tr.outcome-diag-sof-row .quality {
  width: 6%;
}
.outcomes-container tr.outcome-diag-sof-row .diagnostic-effect {
  width: 8%;
}
.outcomes-container tr.outcome-diag-sof-row .comments {
  width: auto;
}
.outcomes-container tr.outcome-diag-sof-row th.table-mode {
  width: 2%;
}
.outcomes-container .prognostic-table > th,
.outcomes-container tr.prognostic-outcome > th {
  vertical-align: middle;
  word-break: break-word;
}
.outcomes-container .prognostic-table .no-of-studies,
.outcomes-container tr.prognostic-outcome .no-of-studies {
  width: 6%;
}
.outcomes-container .prognostic-table .design-studies,
.outcomes-container tr.prognostic-outcome .design-studies,
.outcomes-container .prognostic-table .risk-of-bias,
.outcomes-container tr.prognostic-outcome .risk-of-bias,
.outcomes-container .prognostic-table .inconsistency,
.outcomes-container tr.prognostic-outcome .inconsistency,
.outcomes-container .prognostic-table .indirectness,
.outcomes-container tr.prognostic-outcome .indirectness,
.outcomes-container .prognostic-table .imprecision,
.outcomes-container tr.prognostic-outcome .imprecision {
  width: 7%;
}
.outcomes-container .prognostic-table .other-considerations,
.outcomes-container tr.prognostic-outcome .other-considerations {
  width: 8%;
}
.outcomes-container .prognostic-table .rate,
.outcomes-container tr.prognostic-outcome .rate,
.outcomes-container .prognostic-table .events,
.outcomes-container tr.prognostic-outcome .events,
.outcomes-container .prognostic-table .relative-effect,
.outcomes-container tr.prognostic-outcome .relative-effect {
  width: 10%;
}
.outcomes-container .prognostic-table .quality,
.outcomes-container tr.prognostic-outcome .quality {
  width: 8%;
}
.outcomes-container .prognostic-table .importance,
.outcomes-container tr.prognostic-outcome .importance {
  width: 10%;
}
.outcomes-container .prevs-count-1 tr.outcome-diag-row .diagnostic-effect {
  width: 7%;
}
.outcomes-container .prevs-count-2 tr.outcome-diag-row .diagnostic-effect {
  width: 5%;
}
.outcomes-container .prevs-count-3 tr.outcome-diag-row .diagnostic-effect {
  width: 5%;
}
.outcomes-container th.importance div.table-menu {
  border-left: 3px solid #fff;
}
.outcomes-container th.importance span.table-menu {
  display: inline-block;
  padding-top: 25%;
  height: 100%;
}
.outcomes-container th.importance .cell-label-with-table-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 129px;
}
.outcomes-container th.importance .cell-label-with-table-menu > div {
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.outcomes-container th.importance .cell-label-with-table-menu > div.label {
  position: relative;
}
.outcomes-container th.importance .cell-label-with-table-menu > div.table-menu {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  width: 30px;
  max-width: 30px;
  border-left: 3px solid #fff;
}
.outcomes-container table.lang-de .risk-of-bias,
.outcomes-container table.lang-de .indirectness,
.outcomes-container table.lang-de .inconsistency,
.outcomes-container table.lang-de .imprecision {
  width: 7%;
}
.outcomes-container table.lang-de .quality {
  width: 9%;
}
.outcomes-container table.lang-de .publication-bias {
  width: 9%;
}
.outcomes-container table.lang-de .design-studies {
  width: 8%;
}
.outcomes-container #isof-content {
  border: 0;
  width: 100%;
}
.isof-view {
  display: inline-block;
  padding: 30px;
  width: 100%;
}
.isof-view table {
  height: 1px;
}
.isof-view .message {
  font-size: 16px;
  font-size: 1.6rem;
}
.isof-view .test-dropdown {
  margin-bottom: 10px;
}
.isof-view .table-mode-toggle {
  text-align: right;
}
.isof-view .table-mode-toggle .table-mode {
  position: relative;
  top: 20px;
  right: 30px;
  z-index: 101;
}
#list-box.outcomes-context-menu {
  padding: 15px 0;
  border: 2px solid #29abe2;
}
#list-box.outcomes-context-menu li {
  padding: 0 15px;
  background-color: #fff;
}
#list-box.outcomes-context-menu li.disabled {
  opacity: initial;
  -ms-filter: initial;
  filter: initial;
  display: inline-block;
  position: relative;
}
#list-box.outcomes-context-menu li.disabled .disabled-tooltip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.cell-edit-box {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  -webkit-box-shadow: 0 0 10px 1px #b6b3b3;
  box-shadow: 0 0 10px 1px #b6b3b3;
}
.cell-edit-box .title-container {
  position: relative;
}
.cell-edit-box .title-container h4 {
  margin: 0;
  padding-right: 15px;
}
.cell-edit-box .title-container button.close {
  position: absolute;
  right: 0;
  top: -8px;
  font-size: 25px;
  border: none;
  background: none;
}
.cell-edit-box .outcome-edit__compound-edit input[type="text"],
.cell-edit-box .outcome-edit__compound-edit .options-select-container {
  height: 30px;
}
.cell-edit-box .outcome-edit__compound-edit input[name$="ControlLabel"],
.cell-edit-box .outcome-edit__compound-edit input[name^="absEffect"] {
  margin-right: 10px;
}
.cell-edit-box .outcome-edit__compound-edit span {
  margin: 0 10px;
}
.cell-edit-box .outcome-edit__compound-edit .effect-data .edit-input-block {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.cell-edit-box .outcome-edit__compound-edit .effect-data input[type="text"] {
  width: 75px;
}
.outcome-edit__selection-list ul {
  min-width: 170px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.outcome-edit__selection-list ul .select-option {
  line-height: 30px;
  padding: 0 5px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: background 0.3s ease-out;
  -moz-transition: background 0.3s ease-out;
  -o-transition: background 0.3s ease-out;
  -ms-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
  color: #2d3236;
}
.outcome-edit__selection-list ul .select-option:hover {
  background: #eaeaea;
}
.outcome-edit__selection-list ul .select-option:active,
.outcome-edit__selection-list ul .select-option.selected {
  background: #f2f2f2;
}
.outcome-edit__selection-list ul .select-option[disabled] {
  color: #909293;
}
.outcome-edit__selection-list ul .select-option[disabled]:hover {
  background: none;
}
.outcome-edit__selection-list ul .select-option .select-option {
  padding-left: 10px;
}
.outcome-edit__selection-list ul .clear-selected {
  margin-top: 10px;
  color: #fff;
}
.outcome-edit__compound-edit {
  min-width: 285px;
}
.outcome-edit__compound-edit .validation-container {
  display: inline-block;
}
.outcome-edit__compound-edit .edit-input-block {
  margin-top: 10px;
  padding-right: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.outcome-edit__compound-edit .edit-input-block .attr-name {
  width: 170px;
  text-align: left;
  font-size: 14px;
}
.outcome-edit__compound-edit .edit-input-block input[type='text'] {
  width: 150px;
}
.outcome-edit__compound-edit .edit-input-block input[type='checkbox'] {
  margin: 0 5px;
}
.outcome-edit__compound-edit .edit-input-block .options-select-container {
  width: 145px;
  font-size: 14px;
}
.outcome-edit__compound-edit .edit-input-block .options-select-container .clear-option {
  color: #fff;
}
.outcome-edit__compound-edit .buttons-react {
  margin-top: 10px;
}
#list-box.table-view-selection {
  padding: 16px 5px 5px;
  border: 1px solid #29abe2;
}
#list-box.table-view-selection ul li {
  padding: 10px 15px;
  background: none;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
#list-box.table-view-selection ul li.current {
  font-weight: bold;
}
#list-box.table-view-selection ul li.disabled {
  background-color: #e4e4e1;
  color: #8f8f8e;
}
#list-box.table-view-selection ul li.hline {
  padding: 0;
  border: 1px solid #29abe2;
  margin-bottom: 3px;
}
#list-box.table-view-selection ul li span {
  padding-left: 12px;
}
#list-box.table-view-selection ul li label {
  position: relative;
  left: -10px;
}
#list-box.table-view-selection ul li label input {
  margin-right: 5px;
}
.rob-view .container {
  margin-bottom: 15px;
}
.rob-view .container .assessment-table .assessment-table__header {
  padding-left: 190px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  text-align: center;
}
.rob-view .container .assessment-table .assessment-table__header > div {
  width: 68px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex: 1 0 auto;
  -ms-flexbox: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #3783a5;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}
.rob-view .container .assessment-table .assessment-table__header > div:not(:last-child) {
  border-right: 1px solid #fff;
}
.rob-view .container .assessment-table .assessment-table__references {
  position: relative;
  overflow: visible;
}
.rob-view .container .assessment-table .assessment-table__references .reference {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  text-align: center;
}
.rob-view .container .assessment-table .assessment-table__references .reference:not(:last-child) > div {
  border-bottom: 3px solid #fff;
}
.rob-view .container .assessment-table .assessment-table__references .reference .reference__name {
  width: 190px;
  padding: 3px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.rob-view .container .assessment-table .assessment-table__references .reference > div {
  position: relative;
  width: 68px;
  min-height: 40px;
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  background-color: #f2f2f2;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.rob-view .container .assessment-table .assessment-table__references .reference > div:not(:last-child) {
  border-right: 3px solid #fff;
}
.rob-view .container .assessment-table .assessment-table__references .reference > div .choice {
  width: 100%;
  height: 100%;
  padding: 5px;
  outline: none;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.rob-view .container .assessment-table .assessment-table__references .reference > div .choice.selected {
  background-color: #fff;
}
.rob-view .container .assessment-table .assessment-table__references .reference > div .choice:hover,
.rob-view .container .assessment-table .assessment-table__references .reference > div .choice.active {
  background-color: #dedede;
}
.rob-view .container .level {
  margin: 0 auto;
  width: 24px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rob-view .container .level.low {
  background: #8cbf44;
}
.rob-view .container .level.unclear {
  background: #fda;
}
.rob-view .container .level.high {
  background: #ef5168;
}
.rob-view .outcome-level-rob .assessment-block {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 8px;
}
.rob-view .outcome-level-rob .assessment-block label {
  width: 240px;
  margin-right: 10px;
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  font-weight: bold;
  text-align: right;
}
.rob-view .outcome-level-rob .assessment-block .options-select-container {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
}
.rob-view .outcome-level-rob .recommendation {
  padding: 12px 15px;
  margin-bottom: 8px;
  background-color: #fff8de;
  font-size: 14px;
}
.risk-values-list {
  padding: 10px 0;
  background-color: #fff;
  border: 1px solid #29abe2;
}
.risk-values-list .risk-value-container input[type="radio"] {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}
.risk-values-list .risk-value-container input[type="radio"]:checked + label {
  background-color: #d2dfed;
}
.risk-values-list .risk-value-container label {
  padding: 0 10px;
  display: block;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.risk-values-list .risk-value-container label:hover {
  background-color: #f2f2f2;
}
.risk-value-annotation {
  width: 400px;
  padding: 10px;
  background-color: #fff;
  font-size: 13px;
}
.risk-value-annotation .annotation-label {
  font-weight: bold;
  margin: 10px 0;
}
.risk-value-annotation .annotation-text {
  white-space: pre-wrap;
}
.show-original-version {
  text-align: right;
}
