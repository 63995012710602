@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.box {
  display: none;
  background: #f6f6f6;
  border: 1px solid #bfbfbf;
  padding: 10px 0px;
  font-size: 14px;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  z-index: 100;
  position: absolute;
  margin: 0;
  min-width: 200px;
  overflow: hidden;
}
.box span.dot {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 19px;
  position: absolute;
  border: 1px solid #b5b5b5;
}
.box ul {
  position: relative;
}
.box ul li {
  background: #f6f6f6;
}
.box .edit-input-block {
  white-space: nowrap;
}
.box .edit-input-block.active {
  background-color: #ddeaf9;
}
.box .edit-input-block.active input,
.box .edit-input-block.active textarea {
  border: 1px #9bbde0 solid;
}
.box .edit-input-block.active div.empty {
  background-color: #f6f6f6;
}
.box .v-top {
  vertical-align: top;
}
.box .v-top * {
  vertical-align: top;
}
.box .v-bottom {
  vertical-align: bottom;
}
.box .v-bottom * {
  vertical-align: bottom;
}
.box legend {
  font-weight: bold;
}
.box div.percent {
  position: relative;
}
.box div.percent:after {
  position: absolute;
  top: 7px;
  right: 15px;
  float: right;
  content: '%';
}
.box input.form-control {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  min-width: 45px;
}
.box .text-with-limit textarea {
  padding-right: 0px;
  padding-top: 15px;
}
.box textarea.cell-edit-textarea {
  width: 100%;
  font-size: 14px;
  line-height: 19px;
}
.box label {
  font-size: 14px;
  line-height: 19px;
  white-space: normal;
}
.box label.checkbox {
  position: relative;
  width: 100%;
}
.box label.checkbox input[type="checkbox"] {
  position: absolute;
  top: 9px;
  right: 0px;
}
.box label.footnote-validation {
  color: #f00;
}
.box .content {
  border: none;
  margin: 0;
  padding: 0;
}
.box .content .robins-i-toggle span.info-sign {
  position: relative;
  display: inline-block;
  top: -5px;
  left: 3px;
}
.box .content label {
  margin-left: 5px;
  display: inline-block;
  width: 175px;
  line-height: 30px;
}
.box .content label.long {
  width: auto;
}
.box .content label.middle {
  width: auto;
  margin: 0px 5px;
}
.box .content label.legend {
  font-weight: bold;
}
.box .content label span.text-right {
  float: right;
}
.box .content input {
  width: auto;
}
.box .content input[type=checkbox] {
  width: 12px;
}
.box .content input.number {
  width: 75px;
}
.box .content input.number.short {
  width: 45px !important;
}
.box .content .sup {
  display: inline-block;
  position: relative;
  margin-left: 2px;
  min-width: 10px;
  height: 10px;
}
.box .content .inline {
  display: inline-block;
}
.box .content .inline .selection-list-value {
  display: inline-block;
  width: auto;
  position: relative;
  vertical-align: middle;
  top: -2px;
}
.box .content .buttons button {
  width: 100%;
}
.box .content .caseControl .no-of-participants-embeded label,
.box .content .outcomeLabel .no-of-participants-embeded label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded label {
  width: 150px;
}
.box .content .caseControl .no-of-participants-embeded input,
.box .content .outcomeLabel .no-of-participants-embeded input,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded input,
.box .content .noOfParticipantsStudies .no-of-participants-embeded input,
.box .content .caseControl .no-of-participants-embeded .selection-list-value,
.box .content .outcomeLabel .no-of-participants-embeded .selection-list-value,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .selection-list-value,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .selection-list-value {
  top: 0px;
  width: 213px;
  max-width: 215px;
}
.box .content .caseControl .no-of-participants-embeded .observational-studies-list,
.box .content .outcomeLabel .no-of-participants-embeded .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .observational-studies-list,
.box .content .caseControl .no-of-participants-embeded .robins-i-toggle,
.box .content .outcomeLabel .no-of-participants-embeded .robins-i-toggle,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .robins-i-toggle,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .robins-i-toggle {
  padding-left: 154px;
}
.box .content .caseControl .no-of-participants-embeded .observational-studies-list > .inline,
.box .content .outcomeLabel .no-of-participants-embeded .observational-studies-list > .inline,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .observational-studies-list > .inline,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .observational-studies-list > .inline,
.box .content .caseControl .no-of-participants-embeded .robins-i-toggle > .inline,
.box .content .outcomeLabel .no-of-participants-embeded .robins-i-toggle > .inline,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .robins-i-toggle > .inline,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .robins-i-toggle > .inline {
  margin-top: 5px;
}
.box .content .caseControl .no-of-participants-embeded .robins-i-toggle input,
.box .content .outcomeLabel .no-of-participants-embeded .robins-i-toggle input,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .robins-i-toggle input,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .robins-i-toggle input {
  width: 12px;
}
.box .content .caseControl .no-of-participants-embeded.ACCP label,
.box .content .outcomeLabel .no-of-participants-embeded.ACCP label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.ACCP label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.ACCP label,
.box .content .caseControl .no-of-participants-embeded.SOF_ACCP label,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_ACCP label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_ACCP label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_ACCP label,
.box .content .caseControl .no-of-participants-embeded.SOF_v3 label,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v3 label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v3 label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v3 label,
.box .content .caseControl .no-of-participants-embeded.SOF_v4 label,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v4 label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v4 label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v4 label {
  width: 175px;
}
.box .content .caseControl .no-of-participants-embeded.ACCP .observational-studies-list,
.box .content .outcomeLabel .no-of-participants-embeded.ACCP .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.ACCP .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.ACCP .observational-studies-list,
.box .content .caseControl .no-of-participants-embeded.SOF_ACCP .observational-studies-list,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_ACCP .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_ACCP .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_ACCP .observational-studies-list,
.box .content .caseControl .no-of-participants-embeded.SOF_v3 .observational-studies-list,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v3 .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v3 .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v3 .observational-studies-list,
.box .content .caseControl .no-of-participants-embeded.SOF_v4 .observational-studies-list,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v4 .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v4 .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v4 .observational-studies-list,
.box .content .caseControl .no-of-participants-embeded.ACCP .robins-i-toggle,
.box .content .outcomeLabel .no-of-participants-embeded.ACCP .robins-i-toggle,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.ACCP .robins-i-toggle,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.ACCP .robins-i-toggle,
.box .content .caseControl .no-of-participants-embeded.SOF_ACCP .robins-i-toggle,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_ACCP .robins-i-toggle,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_ACCP .robins-i-toggle,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_ACCP .robins-i-toggle,
.box .content .caseControl .no-of-participants-embeded.SOF_v3 .robins-i-toggle,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v3 .robins-i-toggle,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v3 .robins-i-toggle,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v3 .robins-i-toggle,
.box .content .caseControl .no-of-participants-embeded.SOF_v4 .robins-i-toggle,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v4 .robins-i-toggle,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v4 .robins-i-toggle,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v4 .robins-i-toggle {
  padding-left: 179px;
}
.box .content .caseControl input,
.box .content .outcomeLabel input,
.box .content .noOfParticipantsStudiesFollowUp input,
.box .content .noOfParticipantsStudies input {
  width: 213px;
}
.box .content .caseControl input.half,
.box .content .outcomeLabel input.half,
.box .content .noOfParticipantsStudiesFollowUp input.half,
.box .content .noOfParticipantsStudies input.half {
  width: 104px;
}
.box .content .caseControl input.long,
.box .content .outcomeLabel input.long,
.box .content .noOfParticipantsStudiesFollowUp input.long,
.box .content .noOfParticipantsStudies input.long {
  width: 545px;
}
.box .content .outcomeLabel .sof-outcome-label-edit .selection-list-value {
  display: inline-block;
  max-width: 232px;
}
.box .content .outcomeLabel .sof-outcome-label-edit #possible-scores-range {
  width: 102px;
}
.box .content .outcomeLabel .sof-outcome-label-edit .conventional-scale-limits > div {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
}
.box .content .outcomeLabel .sof-outcome-label-edit .conventional-scale-limits > div input {
  margin-left: 4px;
  width: 115px;
}
.box .content .quality label {
  width: 160px;
}
.box .content .quality .selection-list-value {
  width: 175px;
  max-width: 175px;
}
.box .content .quality .col-5-5 {
  display: inline-block;
  width: 175px;
}
.box .content .quality .col-1.sup {
  display: inline-block;
}
.box .content .relativeEffect label {
  width: 125px;
}
.box .content .relativeEffect label.middle {
  width: auto;
  margin: 0px 5px;
}
.box .content .relativeEffect .select-input-container {
  min-width: 75px;
}
.box .content .otherConsiderations .selection-list-value {
  min-width: 145px;
}
.box .content .controlTotal label,
.box .content .intervention label {
  width: 200px;
}
.box .content .controlTotal label.short,
.box .content .intervention label.short {
  width: 35px;
  text-align: center;
}
.box .content .narrativeDesc textarea {
  min-width: 300px;
  max-width: 300px;
}
.box .content .narrativeDesc div.gdt-editor-container {
  min-width: 300px;
  max-width: 490px;
}
.box .content .control .continuous label,
.box .content .controlRisk .continuous label {
  width: 75px;
}
.box .content .control .continuous label.show-final-score,
.box .content .controlRisk .continuous label.show-final-score {
  width: auto;
}
.box .content .control .continuous label.show-final-score input[type="checkbox"],
.box .content .controlRisk .continuous label.show-final-score input[type="checkbox"] {
  display: inline-block;
  margin-right: 5px;
}
.box .content .control .continuous .selection-list-value,
.box .content .controlRisk .continuous .selection-list-value {
  width: 106px;
}
.box .content .control .continuous input.number,
.box .content .controlRisk .continuous input.number {
  width: 213px;
}
.box .content .control .continuous input.number.half,
.box .content .controlRisk .continuous input.number.half {
  width: 106px;
}
.box .content .compound-edit.designStudies {
  width: 250px;
}
.box .content .compound-edit.designStudies .inline {
  max-width: initial;
}
.box .content .compound-edit.designStudies .selection-list-value {
  max-width: initial;
  min-width: 200px;
  margin-top: 10px;
}
.box .content .compound-edit.designStudies .observational-studies-list {
  margin-top: 10px;
}
.box .content .compound-edit.comments .text-with-limit {
  display: inline-block;
}
.box .content .compound-edit.comments .automatic-narrative {
  background: #eaeaea;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
}
.box .content .compound-edit.comments .automatic-narrative .automatic-narrative-section {
  width: 750px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-align: stretch;
  -mox-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}
.box .content .compound-edit.comments .automatic-narrative .automatic-narrative-section .automatic-narrative-effect-size-container {
  margin-right: initial;
  margin-left: 0;
}
.box .content .compound-edit.comments .automatic-narrative .automatic-narrative-section .selection-text {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.box .content .compound-edit.comments .automatic-narrative .automatic-narrative-section .selection-list-value {
  max-width: initial;
  width: 100%;
}
.box .content .compound-edit.comments .automatic-narrative .automatic-narrative-section .insert-button {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-right: 0;
  margin-right: 2px;
}
.box .content .compound-edit.comments .automatic-narrative .automatic-narrative-section .insert-button button {
  height: 30px;
  width: 100%;
  line-height: 30px;
}
.box .content .compound-edit.comments .automatic-narrative .error-msg {
  color: #d53d10;
}
.box .content .compound-edit.comments .section-label,
.box .content .compound-edit.comments label {
  font-weight: bold;
}
.box .content .compound-edit.comments select {
  margin-left: 5px;
  margin-right: 5px;
}
.box .content .compound-edit.comments textarea {
  width: 350px;
}
.box .content .compound-edit.comments .comments-management textarea {
  margin-left: 5px;
  width: 740px;
}
.box .content .compound-edit.inconclusivePrevalencesDesc textarea,
.box .content .compound-edit.complicationsPrevalencesDesc textarea {
  width: 350px;
}
.box .content .sup {
  vertical-align: top;
  padding: 0;
}
.box .edit-label {
  margin: 0px 10px;
  font-weight: bold;
}
.box span.close {
  color: #a6afb6;
  width: 10px;
  font-size: 25px;
  line-height: 20px;
  float: right;
  padding-right: 2px;
  cursor: pointer;
}
.box .buttons .btn {
  line-height: 28px;
  width: 50%;
}
.box.footnotes-box {
  width: 740px;
  padding: 22px;
  background-color: #fff;
}
.box.footnotes-box .scrolled {
  width: 100%;
}
.box.footnotes-box .clearfix {
  min-height: 20px;
}
.box.footnotes-box .clearfix.with-tabs {
  height: 40px;
}
.box.footnotes-box .search-container {
  width: 40%;
}
.box.footnotes-box .empty-footnotes {
  text-align: center;
  height: 25px;
  padding: 10px 0;
}
.box.footnotes-box .add-new {
  float: right;
  margin: 15px 10px 15px 0;
  font-size: 16px;
}
.box.footnotes-box .add-new:before {
  font-family: 'gdt';
  content: '\e025';
  font-size: 30px;
  vertical-align: super;
  margin-right: 2px;
}
.box.footnotes-box .tab-panels {
  height: 300px;
  overflow: auto;
  margin-top: 5px;
}
.box.footnotes-box .references-list,
.box.footnotes-box .footnotes-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.box.footnotes-box .references-list li,
.box.footnotes-box .footnotes-list li {
  display: table;
  width: 100%;
  height: 40px;
  background-color: #f2f2f2;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.box.footnotes-box .references-list li.active,
.box.footnotes-box .footnotes-list li.active,
.box.footnotes-box .references-list li:hover,
.box.footnotes-box .footnotes-list li:hover {
  background-color: #f7f7f7;
}
.box.footnotes-box .references-list li .hidden,
.box.footnotes-box .footnotes-list li .hidden {
  display: none;
}
.box.footnotes-box .references-list li .display-footnote,
.box.footnotes-box .footnotes-list li .display-footnote,
.box.footnotes-box .references-list li .edit-footnote,
.box.footnotes-box .footnotes-list li .edit-footnote,
.box.footnotes-box .references-list li .display-reference,
.box.footnotes-box .footnotes-list li .display-reference {
  display: table-row;
}
.box.footnotes-box .references-list li .display-footnote > div,
.box.footnotes-box .footnotes-list li .display-footnote > div,
.box.footnotes-box .references-list li .edit-footnote > div,
.box.footnotes-box .footnotes-list li .edit-footnote > div,
.box.footnotes-box .references-list li .display-reference > div,
.box.footnotes-box .footnotes-list li .display-reference > div,
.box.footnotes-box .references-list li .display-footnote label,
.box.footnotes-box .footnotes-list li .display-footnote label,
.box.footnotes-box .references-list li .edit-footnote label,
.box.footnotes-box .footnotes-list li .edit-footnote label,
.box.footnotes-box .references-list li .display-reference label,
.box.footnotes-box .footnotes-list li .display-reference label {
  display: table-cell;
  vertical-align: middle;
}
.box.footnotes-box .references-list li .display-footnote > div.checkbox,
.box.footnotes-box .footnotes-list li .display-footnote > div.checkbox,
.box.footnotes-box .references-list li .edit-footnote > div.checkbox,
.box.footnotes-box .footnotes-list li .edit-footnote > div.checkbox,
.box.footnotes-box .references-list li .display-reference > div.checkbox,
.box.footnotes-box .footnotes-list li .display-reference > div.checkbox {
  background-color: #fff;
  width: 20px;
}
.box.footnotes-box .references-list li .display-footnote > div.item-controls,
.box.footnotes-box .footnotes-list li .display-footnote > div.item-controls,
.box.footnotes-box .references-list li .edit-footnote > div.item-controls,
.box.footnotes-box .footnotes-list li .edit-footnote > div.item-controls,
.box.footnotes-box .references-list li .display-reference > div.item-controls,
.box.footnotes-box .footnotes-list li .display-reference > div.item-controls {
  width: 30px;
  padding-right: 5px;
  text-align: right;
}
.box.footnotes-box .references-list li .display-footnote > .ellipsis-wrapper,
.box.footnotes-box .footnotes-list li .display-footnote > .ellipsis-wrapper,
.box.footnotes-box .references-list li .edit-footnote > .ellipsis-wrapper,
.box.footnotes-box .footnotes-list li .edit-footnote > .ellipsis-wrapper,
.box.footnotes-box .references-list li .display-reference > .ellipsis-wrapper,
.box.footnotes-box .footnotes-list li .display-reference > .ellipsis-wrapper {
  padding-left: 10px;
}
.box.footnotes-box .references-list li .display-footnote button.edit,
.box.footnotes-box .footnotes-list li .display-footnote button.edit,
.box.footnotes-box .references-list li .edit-footnote button.edit,
.box.footnotes-box .footnotes-list li .edit-footnote button.edit,
.box.footnotes-box .references-list li .display-reference button.edit,
.box.footnotes-box .footnotes-list li .display-reference button.edit,
.box.footnotes-box .references-list li .display-footnote button.save,
.box.footnotes-box .footnotes-list li .display-footnote button.save,
.box.footnotes-box .references-list li .edit-footnote button.save,
.box.footnotes-box .footnotes-list li .edit-footnote button.save,
.box.footnotes-box .references-list li .display-reference button.save,
.box.footnotes-box .footnotes-list li .display-reference button.save,
.box.footnotes-box .references-list li .display-footnote button.delete,
.box.footnotes-box .footnotes-list li .display-footnote button.delete,
.box.footnotes-box .references-list li .edit-footnote button.delete,
.box.footnotes-box .footnotes-list li .edit-footnote button.delete,
.box.footnotes-box .references-list li .display-reference button.delete,
.box.footnotes-box .footnotes-list li .display-reference button.delete {
  margin: 0;
  float: none;
  padding: 0;
}
.box.footnotes-box .references-list li .display-reference label,
.box.footnotes-box .footnotes-list li .display-reference label {
  display: table-row;
}
.box.footnotes-box .references-list li .display-reference label span,
.box.footnotes-box .footnotes-list li .display-reference label span {
  display: table-cell;
  vertical-align: middle;
}
.box.footnotes-box .references-list li .display-reference label .attachment-link,
.box.footnotes-box .footnotes-list li .display-reference label .attachment-link {
  width: 65px;
  color: #009cca;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 5px;
}
.box.footnotes-box .references-list li .display-reference label .attachment-link a,
.box.footnotes-box .footnotes-list li .display-reference label .attachment-link a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #009cca;
  text-decoration: none;
}
.box.footnotes-box .references-list li .display-reference label .attachment-link a:before,
.box.footnotes-box .footnotes-list li .display-reference label .attachment-link a:before {
  font-family: 'gdt';
  content: '\e095';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.box.footnotes-box .references-list li .display-reference label .attachment-link a *,
.box.footnotes-box .footnotes-list li .display-reference label .attachment-link a * {
  margin-left: 0.5rem;
}
.box.footnotes-box .references-list li .display-reference label .attachment-link a:hover,
.box.footnotes-box .footnotes-list li .display-reference label .attachment-link a:hover {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.box.footnotes-box .references-list li .display-reference label .attachment-link a:before,
.box.footnotes-box .footnotes-list li .display-reference label .attachment-link a:before {
  margin-right: 5px;
}
.box.footnotes-box .references-list li .edit-footnote .limit-info,
.box.footnotes-box .footnotes-list li .edit-footnote .limit-info,
.box.footnotes-box .references-list li .edit-footnote .inner-text,
.box.footnotes-box .footnotes-list li .edit-footnote .inner-text {
  color: #000080;
  font-size: 12px;
}
.box.footnotes-box .references-list li .edit-footnote .text-with-limit textarea,
.box.footnotes-box .footnotes-list li .edit-footnote .text-with-limit textarea {
  width: 100%;
  padding: 5px;
}
.box.footnotes-box .references-list li .edit-footnote .text-with-limit .inner-text,
.box.footnotes-box .footnotes-list li .edit-footnote .text-with-limit .inner-text {
  display: block;
  position: static;
  text-align: right;
}
.box .edit-options-list {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0;
  padding-top: 5px;
}
.box .edit-options-list li {
  padding: 0 10px;
  height: 32px;
}
.box .edit-options-list li.edit-option {
  white-space: nowrap;
  line-height: 30px;
  cursor: pointer;
}
.box .edit-options-list li.clear-option {
  margin: 10px 10px 0px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  background: #009cca;
}
.box .edit-options-list li.clear-option.active,
.box .edit-options-list li.clear-option:hover {
  background: #00a4d4;
}
.box .edit-options-list li input[type="checkbox"] {
  float: left;
  margin-top: 8px;
  margin-right: 10px;
}
.box .edit-options-list li input[type="text"] {
  height: 30px;
  line-height: 30px;
}
.box .edit-options-list li label {
  line-height: 30px;
  margin: 0;
  padding: 0;
  display: block;
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.box .edit-options-list li .footnote-input-field {
  width: 485px;
  font-size: 14px;
}
.box .edit-options-list li:hover {
  background: #f2f2f2;
}
.box .edit-options-list li.active {
  background: #eaeaea;
}
.box .edit-options-list li.current {
  background: #f2f2f2;
}
.box .select-input-container.other {
  min-width: 138px;
}
.box .select-input-container.other span.selection-list-value {
  width: 71px !important;
  display: inline-block;
}
.box .select-input-container.other input {
  width: 71px;
}
.box .prevalences {
  width: auto;
}
.box .prevalences .edit-input-block {
  padding: 2px 5px;
}
.box .prevalences .percent {
  float: left;
}
.box .prevalences .percent input.number.short {
  text-align: right;
  width: 45px !important;
  margin-right: 10px;
  padding-right: 17px;
}
.box .prevalences .text-with-limit {
  float: left;
  width: 300px;
}
.box .prevalences .text-with-limit .inner-text {
  font-size: 13px;
  width: 280px;
  display: block;
  top: 1px;
  right: 15px;
  text-align: right;
  background: #fff;
}
.box .prevalences .sup {
  float: right;
}
