@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#dashboard-container {
  padding: 0px 10px;
}
.dashboard-toolbar {
  padding: 0px 20px;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.dashboard-toolbar h2 {
  -webkit-flex: 1 1 0;
  -ms-flexbox: 1 1 0;
  flex: 1 1 0;
}
.review-statuses input[type="checkbox"] {
  margin-right: 5px;
}
.review-statuses .review-label {
  font-weight: bold;
  width: 175px;
}
.review-statuses .review-status-label {
  text-transform: uppercase;
}
.review-statuses .review-status-label.complete {
  color: #008000;
}
.review-statuses .review-status-label.incomplete {
  color: #f00;
}
.stats-label {
  width: 300px;
  font-weight: bold;
}
.dashboard-history-modal {
  height: 80vh;
  width: 80vw !important;
}
.dashboard-history-modal .ep-modal__content {
  height: 100%;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal {
  height: 100%;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filters__filter {
  display: -webkit-lex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filters__filter > div {
  margin-right: 5px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filter__category,
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filter__items {
  width: 200px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filter__items .select-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filter__items .select-sign {
  margin-left: 5px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filters__refresh-button {
  line-height: 18px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filters__refresh-button button {
  background-image: none;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__filters .filters__refresh-button button:before {
  margin-right: 5px;
  font-size: 18px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table {
  height: 100%;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__header,
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__row {
  height: 40px;
  display: grid;
  grid-template-columns: 211px 1fr 192px;
  grid-gap: 2px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__header div,
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__row div {
  padding: 5px;
  height: 40px;
  line-height: 30px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__header {
  font-weight: bold;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__body {
  overflow-y: auto;
  height: calc(100% - 105px);
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__row {
  margin-top: 2px;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__row div {
  background: #f2f2f2;
}
.dashboard-history-modal .ep-modal__content .dashboard-history-modal__history-modal .history-modal__history-table .history-table__row div.row__history-entry-type {
  text-overflow: ellipsis;
}
