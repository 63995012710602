@-moz-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  to {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.highlight-project {
  -webkit-animation: 2s splash;
  -moz-animation: 2s splash;
  -o-animation: 2s splash;
  -ms-animation: 2s splash;
  animation: 2s splash;
}
.start-new-project-modal {
  margin-top: 10%;
}
.start-new-project-modal h2 {
  text-align: center;
}
.start-new-project-modal input[type=text] {
  width: 100%;
}
.start-new-project-modal label {
  display: block;
  margin-bottom: 15px;
}
.start-new-project-modal .create {
  background-color: #00c0b3;
}
.start-new-project-modal .types {
  position: relative;
  margin-top: 30px;
}
.start-new-project-modal .types h3 {
  margin-left: 20px;
  font-size: 15px;
  font-size: 1.5rem;
}
.start-new-project-modal .types span.info-sign {
  position: absolute;
  color: #747c82;
  left: -2px;
  top: -2px;
  font-size: 16px;
  font-size: 1.6rem;
}
.start-new-project-modal .types span.info-sign:before {
  font-family: 'gdt';
  content: '\e003';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.start-new-project-modal .types span.info-sign * {
  margin-left: 0.5rem;
}
.create-adolopment-modal .numbered-items-container,
.create-adolopment-modal .description {
  margin-bottom: 20px;
}
.create-adolopment-modal .question-without-etd {
  width: 100%;
  line-height: 40px;
  background: #f2f2f2;
}
.link-button {
  border: none;
  background: inherit;
  cursor: pointer;
  color: #4484c5;
}
.link-button:hover {
  text-decoration: underline;
}
@media screen and (min-height: 690px) {
  .new-user-view > div:nth-child(1) {
    min-height: 235px;
    min-width: 555px;
  }
  .notifier.notifier-theme-gdt.new-user-screen > div h2 {
    margin: 15px 0px 30px;
  }
  .capabilities-view #main-menu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 10px 20px;
    text-shadow: none;
  }
  .capabilities-view #main-menu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
  .capabilities-view #submenu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 10px 20px;
    text-shadow: none;
  }
  .capabilities-view #submenu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
}
@media screen and (max-height: 689px) {
  .new-user-view > div:nth-child(1) {
    min-height: 196px;
    min-width: 555px;
  }
  .notifier.notifier-theme-gdt.new-user-screen > div h2 {
    margin: 10px 0px 15px;
  }
  .capabilities-view #main-menu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 7px 20px;
    text-shadow: none;
  }
  .capabilities-view #main-menu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
  .capabilities-view #submenu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 7px 20px;
    text-shadow: none;
  }
  .capabilities-view #submenu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
}
.welcome-view {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: box !important;
  display: flex !important;
  height: 100%;
  overflow: auto;
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.welcome-view .tooltip-container .tooltip {
  background: #fff;
  border: 1px solid #bfbfbf;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  border: 1px solid #bfbfbf;
}
.welcome-view .tooltip-container .tooltip .tooltip__arrow {
  color: #bfbfbf;
}
.welcome-view .projects-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 940px;
  max-height: 100%;
}
.welcome-view .projects-container .projects-header-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 40px;
  margin-bottom: 40px;
}
.welcome-view .projects-container .projects-header-container .search-container {
  -webkit-flex: 0 0 33%;
  -ms-flexbox: 0 0 33%;
  flex: 0 0 33%;
}
.welcome-view .projects-container .projects-header-container .replication-messages {
  -webkit-flex: 0 1 auto;
  -ms-flexbox: 0 1 auto;
  flex: 0 1 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 5px;
}
.welcome-view .projects-container .projects-header-container .replication-messages .replication-status {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 30px;
  color: #fff;
}
.welcome-view .projects-container .projects-header-container .replication-messages .replication-incomplete {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 30px;
  color: #f00;
}
.welcome-view .projects-container .projects-header-container .projects-buttons {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.welcome-view .projects-container .projects-header-container .projects-buttons .new-project,
.welcome-view .projects-container .projects-header-container .projects-buttons .import-project,
.welcome-view .projects-container .projects-header-container .projects-buttons .continue {
  height: 30px;
  padding: 0 5px;
  line-height: normal;
}
.welcome-view .projects-container .projects-header-container .projects-buttons .new-project,
.welcome-view .projects-container .projects-header-container .projects-buttons .import-project {
  -webkit-flex: 0 0 118px;
  -ms-flexbox: 0 0 118px;
  flex: 0 0 118px;
  margin-left: 10px;
}
.welcome-view .projects-container .projects-header-container .projects-buttons .continue {
  -webkit-flex: 0 0 185px;
  -ms-flexbox: 0 0 185px;
  flex: 0 0 185px;
}
.welcome-view .projects-container .projects-invitations-container {
  min-height: 70px;
  margin-bottom: 56px;
  -webkit-flex: 0 1 auto;
  -ms-flexbox: 0 1 auto;
  flex: 0 1 auto;
  overflow: auto;
}
.welcome-view .projects-container .projects-invitations-container.empty {
  margin-bottom: 0;
}
.welcome-view .projects-container .projects-tabs {
  min-height: 240px;
  position: relative;
  overflow: auto;
  -webkit-flex: 0 1 auto;
  -ms-flexbox: 0 1 auto;
  flex: 0 1 auto;
}
.welcome-view .projects-container .projects-tabs > div:not(.spinner-el) {
  height: 100%;
}
.welcome-view .projects-container .projects-tabs .tab.onboarding-target {
  position: relative;
}
.welcome-view .projects-container .projects-tabs .tab.onboarding-target:before {
  position: absolute;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  content: ' ';
  border: 2px solid #d57373;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles {
  position: absolute;
  top: -5px;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .sort-by,
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .view-toggles {
  display: inline-block;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .sort-by .options-select-container .options-container.visible {
  border: 1px solid #009cca;
  color: #000;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .sort-by .options-select-container .select-input {
  border: none;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .sort-by .options-select-container .select-sign {
  font-size: 12px;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .view-toggles {
  margin-left: 20px;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .view-toggles button:before {
  font-size: 30px;
  color: #fff;
}
.welcome-view .projects-container .projects-tabs .sort-with-view-toggles .view-toggles .selected {
  cursor: default;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.welcome-view .projects-container .projects-tabs .tabs-container {
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
}
.welcome-view .projects-container .projects-tabs .tabs-container .tabspane {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  height: auto;
}
.welcome-view .projects-container .projects-tabs .tabs-container .tab-content {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
  overflow: auto;
}
.welcome-view .projects-container .projects-tabs .tabs-container .tab-content .information-tooltip {
  max-width: 250px;
}
.welcome-view .projects-container .projects-tabs .tabs-container .tab-content .invitation-controls div {
  display: inline-block;
}
.welcome-view .projects-container .projects-tabs .tabs-container .tab-content .invitation-controls button {
  font-size: 24px;
  font-size: 2.4rem;
}
.welcome-view .projects-container .projects-tabs .tabs-container .tab-content .invitation-controls button.accept-icon {
  margin-left: 10px;
}
.welcome-view .projects-container .projects-tabs .tabs-container .tab-content .no-project {
  color: #fff;
  margin: 15px 0 0 15px;
}
.welcome-view .caption,
.welcome-view .caption:link,
.welcome-view .caption:visited,
.welcome-view .caption:hover,
.welcome-view .caption:active {
  color: inherit;
  text-decoration: none;
}
.welcome-view .caption:hover {
  text-decoration: underline;
}
.new-user-view {
  padding: 1rem;
  color: #2d3236;
  min-width: 550px;
}
.new-user-view .youtube-container {
  float: left;
  margin-right: 30px;
}
.welcome-start-new {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.welcome-start-new > div {
  padding: 3rem 2rem;
}
.welcome-start-new .project-types {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-right: 100px;
}
.welcome-learn-view {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 3rem 2rem;
}
.welcome-learn-view .support,
.welcome-learn-view .learn {
  margin-right: 9rem;
}
.welcome-learn-view menu[type='toolbar'] {
  -webkit-flex: 0 0 250px;
  -ms-flexbox: 0 0 250px;
  flex: 0 0 250px;
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 0;
}
.welcome-learn-view menu[type='toolbar'] button {
  text-align: left;
}
.welcome-learn-view menu[type='toolbar'] a {
  color: #2d3236;
  text-decoration: none;
}
.welcome-learn-view menu[type='toolbar'] a:hover {
  color: #8b8d90;
}
.welcome-learn-view .buttons-block {
  text-align: center;
  margin-top: 3rem;
}
.welcome-learn-view .buttons-block button {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
  padding: 7px 20px;
  color: #5b666e;
}
.welcome-learn-view .buttons-block button:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.welcome-learn-view .buttons-block button:hover {
  background: #e2e2e2;
}
.welcome-learn-view #dont-show-again {
  display: block;
  float: right;
  font-size: 13px;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.welcome-learn-view #dont-show-again button {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
}
.welcome-learn-view #dont-show-again button:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.welcome-learn-view #dont-show-again button:hover {
  text-decoration: underline;
  color: #2d3236;
}
.notifier.notifier-theme-gdt.new-user-screen > div .notifier-title {
  margin: 15px 0 0;
  padding: 0;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 2.6rem;
}
.notifier.notifier-theme-gdt.new-user-screen > div .unstyled-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.notifier.notifier-theme-gdt.new-user-screen > div .unstyled-list li button {
  padding: 5px 0px 15px;
}
.capabilities-view {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.capabilities-view #main-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.capabilities-view #main-menu button {
  width: 100%;
}
.capabilities-view #main-menu button.selected {
  background: #fff;
}
.capabilities-view #main-menu button:after {
  content: '\203a';
  color: #a7a7a7;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2rem;
  float: right;
  padding-left: 10px;
}
.capabilities-view #main-menu ul {
  display: none;
}
.capabilities-view #submenu {
  background: #fff;
}
.capabilities-view #submenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.capabilities-view #submenu ul li.separated {
  border-top: 1px solid #bfbfbf;
}
.capabilities-view #submenu button {
  width: 350px;
  font-size: 16px;
}
.welcome-learn-view .capabilities h2 {
  text-indent: 2rem;
}
.welcome-learn-view .capabilities .capabilities-view #main-menu button.selected {
  background: #d0d0d0;
}
.welcome-learn-view .capabilities #submenu {
  background: #d0d0d0;
}
.notifier-dialog.info-box .notifier-inner {
  overflow: auto;
}
.notifier.notifier-theme-gdt.new-user-screen > div {
  padding-bottom: 10px;
}
.notifier.notifier-theme-gdt.new-user-screen > div .notifier-message .new-user-view {
  padding-bottom: 0px;
}
.notifier.notifier-theme-gdt.new-user-screen > div .notifier-message .new-user-view .get-started {
  width: 110px;
  margin: 0 auto;
}
button.notifier-close {
  margin-top: -25px;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-title {
  margin: 0;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns {
  background: inherit;
  border-top: none;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns button {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
  text-align: center;
  background: #cecece;
  color: #5b666e;
  margin-right: 10px;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns button:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns:hover {
  background: #e2e2e2;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns.finish {
  background: #c9dac2;
  color: #2ba82e;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns.finish:hover {
  background: #eef4ec;
  color: #69bf6b;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns.finish:disabled {
  background: #d9e2d6;
  color: #80c582;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner {
  width: 550px;
  padding: 30px;
  background: -webkit-linear-gradient(#fff, #fff), -webkit-linear-gradient(left, #e95400, #d015ff);
  background: -moz-linear-gradient(#fff, #fff), -moz-linear-gradient(left, #e95400, #d015ff);
  background: -o-linear-gradient(#fff, #fff), -o-linear-gradient(left, #e95400, #d015ff);
  background: -ms-linear-gradient(#fff, #fff), -ms-linear-gradient(left, #e95400, #d015ff);
  background: linear-gradient(#fff, #fff), linear-gradient(to right, #e95400, #d015ff);
  -webkit-background-size: calc(100% - 2px) calc(100% - 2px), cover;
  -moz-background-size: calc(100% - 2px) calc(100% - 2px), cover;
  background-size: calc(100% - 2px) calc(100% - 2px), cover;
  background-position: center, top left;
  background-repeat: no-repeat, no-repeat;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner .notifier-title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner .notifier-close:before {
  color: #2d3236;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner .caption {
  margin: 20px 0;
  font-size: 14px;
  line-height: 20px;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner h4 {
  line-height: 20px;
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: bold;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner .amount-options {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -font-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner .amount-options > div input {
  display: none;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner .amount-options > div input:checked ~ label {
  background-color: #ff677b;
  color: #fff;
  font-weight: bold;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner .amount-options > div label {
  display: block;
  width: 88px;
  line-height: 80px;
  text-align: center;
  font-size: 21px;
  border: 1px solid #ff677b;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner button.donate {
  width: 100%;
  height: 47px;
  background: -webkit-linear-gradient(left, #e95400, #d015ff);
  background: -moz-linear-gradient(left, #e95400, #d015ff);
  background: -o-linear-gradient(left, #e95400, #d015ff);
  background: -ms-linear-gradient(left, #e95400, #d015ff);
  background: linear-gradient(to right, #e95400, #d015ff);
  text-transform: uppercase;
  letter-spacing: 2.23px;
  font-size: 24px;
  font-weight: bold;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner a.other-donations,
.notifier.notifier-theme-gdt.donation-modal .notifier-inner a.other-donations:visited,
.notifier.notifier-theme-gdt.donation-modal .notifier-inner a.other-donations:active {
  text-decoration: none;
  font-style: italic;
  font-size: 14px;
  color: #2d3236;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner a.other-donations:hover,
.notifier.notifier-theme-gdt.donation-modal .notifier-inner a.other-donations:visited:hover,
.notifier.notifier-theme-gdt.donation-modal .notifier-inner a.other-donations:active:hover {
  text-shadow: 0 1px 10px #808080;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner #dont-show-again {
  font-size: 12px;
}
.notifier.notifier-theme-gdt.donation-modal .notifier-inner #dont-show-again input {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.project-label__container {
  background: #fff;
  padding: 10px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #009cca;
  outline: none;
  -webkit-box-shadow: -1px 0px 4px rgba(0,0,0,0.5);
  box-shadow: -1px 0px 4px rgba(0,0,0,0.5);
}
.project-label__container .label-change-tooltip {
  padding: 10px 10px 5px 5px;
}
.project-label__container .label-change-tooltip input[type="radio"] {
  cursor: pointer;
}
.project-label__container .label-change-tooltip input[type="text"] {
  width: 200px;
}
.project-label__container .label-change-tooltip label {
  cursor: default;
}
.project-label__container .label-change-tooltip .label {
  margin-bottom: 5px;
  white-space: nowrap;
}
.project-label__container .label-change-tooltip .label .label-text-box {
  display: inline-block;
  padding: 0 3px;
  max-width: 130px;
  overflow-x: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}
.project-label__container .label-change-tooltip .label .label-text-box:hover {
  cursor: pointer;
}
.project-label__container .label-change-tooltip .label .label-check {
  display: inline-block;
  width: 21px;
  height: 21px;
  border: 1px solid #808080;
  cursor: pointer;
}
.project-label__container .label-change-tooltip .label .label-check.checked:before {
  margin-left: 2px;
  font-family: gdt;
  content: '\e060';
}
.project-label__container .label-change-tooltip .label input,
.project-label__container .label-change-tooltip .label label {
  margin-left: 5px;
  line-height: 20px;
  vertical-align: middle;
}
.project-edit-fields {
  margin-bottom: 30px;
}
.project-edit-fields label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.project-edit-fields label:first-child {
  margin-bottom: 20px;
}
.project-edit-fields label span {
  -webkit-flex: 0 0 80px;
  -ms-flexbox: 0 0 80px;
  flex: 0 0 80px;
  width: 80px;
  font-weight: bold;
}
.project-edit-fields label input {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  background-color: #fff;
}
.project-actions-box {
  min-width: 138px;
  padding: 16px;
  border: 1px solid #29abe2;
  background-color: #fff;
}
.project-actions-box .actions-separator {
  border-top: 1px solid #3783a5;
}
.project-actions-box .actions-group-label {
  font-weight: bold;
  font-size: 14px;
}
.project-actions-box .ep-switcher {
  padding-left: 0px;
  font-size: 14px;
}
.project-actions-box .ep-switcher .ep-switcher__switcher {
  top: 0px;
}
.project-actions-box > div {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  line-height: 25px;
}
.project-actions-box > div:hover {
  font-weight: bold;
}
.project-actions-box > div:not(:last-child) {
  margin-bottom: 10px;
}
.project-actions-box > div .btn-icon-container {
  line-height: initial;
}
.project-actions-box > div .btn-icon-container .label-text {
  font-size: 14px;
}
.project-actions-box > div .btn-icon-container button:before {
  font-size: 20px;
}
.projects-list .projects-list__project--inner.project-invitation,
.projects-list .caption-row {
  padding: 0;
  background-color: #f2f2f2;
  -webkit-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -moz-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -o-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -ms-transition: background-color 0.2s ease-in, color 0.2s ease-in;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  -webkit-box-shadow: inset 0px 5px 10px rgba(0,0,0,0.3);
  box-shadow: inset 0px 5px 10px rgba(0,0,0,0.3);
}
.projects-list .projects-list__project--inner.project-invitation:hover,
.projects-list .caption-row:hover,
.projects-list .projects-list__project--inner.project-invitation.active,
.projects-list .caption-row.active {
  background-color: #005aa5;
  color: #fff;
}
.projects-list .projects-list__project--inner.project-invitation .controls,
.projects-list .caption-row .controls {
  z-index: 90;
  -webkit-flex: 0 0 80px;
  -ms-flexbox: 0 0 80px;
  flex: 0 0 80px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.projects-list .projects-list__project--inner.project-invitation .controls button::before,
.projects-list .caption-row .controls button::before {
  color: #ffa745;
}
.projects-list .collapsed-content-row {
  padding: 0;
}
.projects-list .projects-list__project--inner.project-invitation {
  background-color: #fff8de;
  padding-right: 30px;
}
.projects-list .projects-list__project--inner.project-invitation.invalid {
  background-color: #f2f2f2;
}
.projects-list .projects-list__project--inner.project-invitation.invalid:hover {
  background-color: #005aa5;
}
.projects-list .projects-list__project--inner.project-invitation:not(:last-child) {
  margin-bottom: 5px;
}
.projects-list .projects-list__project--inner.project-invitation .project-invitation-actions {
  z-index: 1;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}
.projects-list .projects-list__project--inner.project-invitation .project-invitation-actions .invitation-action {
  width: 150px;
  color: #fff;
  border: none;
}
.projects-list .projects-list__project--inner.project-invitation .project-invitation-actions .invitation-action:not(:first-child) {
  margin-left: 20px;
}
.projects-list .projects-list__project--inner.project-invitation .project-invitation-actions .invitation-action.invitation-accept {
  background-color: #009cca;
}
.projects-list .projects-list__project--inner.project-invitation .project-invitation-actions .invitation-action.invitation-project-add {
  background-color: #00c0b3;
}
.projects-list .projects-list__project--inner.project-invitation .project-invitation-actions .invitation-action.invitation-reject {
  background-color: #9dbfcc;
}
.projects-list .projects-list__project--inner {
  position: relative;
  height: 95px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.projects-list .projects-list__project--inner.project-invitation {
  height: 60px;
}
.projects-list .projects-list__project--inner .project-label {
  z-index: 90;
  height: 100%;
  -webkit-flex: 0 0 30px;
  -ms-flexbox: 0 0 30px;
  flex: 0 0 30px;
  background-color: #e6e6e6;
  -webkit-box-shadow: inset 0px 5px 10px rgba(0,0,0,0.3);
  box-shadow: inset 0px 5px 10px rgba(0,0,0,0.3);
  outline: none;
}
.projects-list .projects-list__project--inner .project-name-customer-details {
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 0px;
}
.projects-list .projects-list__project--inner .project-name-customer-details .project-name {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex: 1;
  flex-grow: 1;
}
.projects-list .projects-list__project--inner .project-name-customer-details,
.projects-list .projects-list__project--inner .project-invitation-details {
  margin-left: 10px;
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
}
.projects-list .projects-list__project--inner .project-name-customer-details .project-name,
.projects-list .projects-list__project--inner .project-invitation-details .project-name,
.projects-list .projects-list__project--inner .project-name-customer-details .invitation-type,
.projects-list .projects-list__project--inner .project-invitation-details .invitation-type {
  font-size: 16px;
  font-weight: bold;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -mox-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.projects-list .projects-list__project--inner .project-name-customer-details .project-name a,
.projects-list .projects-list__project--inner .project-invitation-details .project-name a,
.projects-list .projects-list__project--inner .project-name-customer-details .invitation-type a,
.projects-list .projects-list__project--inner .project-invitation-details .invitation-type a,
.projects-list .projects-list__project--inner .project-name-customer-details .project-name a:hover,
.projects-list .projects-list__project--inner .project-invitation-details .project-name a:hover,
.projects-list .projects-list__project--inner .project-name-customer-details .invitation-type a:hover,
.projects-list .projects-list__project--inner .project-invitation-details .invitation-type a:hover,
.projects-list .projects-list__project--inner .project-name-customer-details .project-name a:visited,
.projects-list .projects-list__project--inner .project-invitation-details .project-name a:visited,
.projects-list .projects-list__project--inner .project-name-customer-details .invitation-type a:visited,
.projects-list .projects-list__project--inner .project-invitation-details .invitation-type a:visited {
  text-decoration: none;
  color: inherit;
}
.projects-list .projects-list__project--inner .project-name-customer-details .project-customer,
.projects-list .projects-list__project--inner .project-invitation-details .project-customer {
  font-size: 13px;
}
.projects-list .projects-list__project--inner .project-name-customer-details .invitation-details,
.projects-list .projects-list__project--inner .project-invitation-details .invitation-details {
  font-size: 14px;
}
.projects-list .projects-list__project--inner .project-name-customer-details .invitation-details,
.projects-list .projects-list__project--inner .project-invitation-details .invitation-details {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.projects-list .projects-list__project--inner .project-start-counter {
  padding: 0px 20px;
  border: 1px solid #009cca;
  margin-right: 5px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  background: #009cca;
}
.projects-list .projects-list__project--inner .project-replication {
  text-align: right;
  margin: 0 30px;
  -webkit-flex: 0 0 70px;
  -ms-flexbox: 0 0 70px;
  flex: 0 0 70px;
}
.projects-list .projects-list__project--inner .project-replication .ep-switcher {
  margin: 0;
}
.projects-list .projects-list__project--inner .project-replication .ep-switcher .ep-switcher__switcher {
  top: 8px;
}
.projects-list .projects-list__project--inner .project-replication .ep-switcher .ep-switcher__switch-btn-text {
  margin-left: 10px;
}
.projects-list .projects-list__project--inner .project-replication .ep-switcher .ep-switcher__switch-btn-text .replication-progress__switcher-text {
  display: inline-block;
  text-align: right;
  width: 82px;
}
.projects-list .projects-list__project--inner .project-last-modified {
  margin: 0;
  font-size: 12px;
}
.projects-list .projects-list__project--inner .project-last-modified .project-last-modified__label {
  display: inline-block;
  margin-right: 5px;
  font-weight: bold;
}
.projects-list .projects-list__project--inner .project-actions {
  font-size: 20px;
}
.projects-list .projects-list__project--inner .project-actions button::before {
  color: #ffa745;
}
.projects-list .projects-list__project--inner .project-name-customer-details,
.projects-list .projects-list__project--inner .project-replication,
.projects-list .projects-list__project--inner .project-actions {
  z-index: 90;
}
.projects-list .project-questions {
  background-color: #fff;
  margin-right: -40px;
  padding: 15px;
}
.projects-list .project-questions h2 {
  margin: 8px 0;
}
.invitation-dialog .message,
.workspace-select-dialog .message {
  margin-bottom: 20px;
}
.invitation-dialog .options-select-container,
.workspace-select-dialog .options-select-container {
  margin-bottom: 20px;
  width: 100%;
}
.send-project-copy-dialog .email-form {
  margin-bottom: 20px;
}
.send-project-copy-dialog label {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -webkit-lex;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
}
.send-project-copy-dialog label > * {
  -webkit-flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.send-project-copy-dialog label span {
  -webkit-flex: 0 0 auto;
  -ms-flexbox: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 10px;
}
.send-project-copy-dialog label input {
  width: 100%;
}
.PopupBox_Container .user-menu__container.onboarding-target {
  border: 2px solid #d57373;
}
.projects-onboarding-proposal .proposal-text {
  white-space: pre-wrap;
}
@-moz-keyframes splash {
  50% {
    background: #00c0b3;
  }
}
@-webkit-keyframes splash {
  50% {
    background: #00c0b3;
  }
}
@-o-keyframes splash {
  50% {
    background: #00c0b3;
  }
}
@keyframes splash {
  50% {
    background: #00c0b3;
  }
}
